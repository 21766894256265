import {
  fetchAllPayrolls,
  fetchPayrollById,
  fetchUserPayrolls,
} from '@/services/payrollsService'

export const payrollsModule = {
  namespaced: true,
  state: {
    payrolls: [],
  },
  mutations: {
    setPayrolls(state, payrolls) {
      state.payrolls = payrolls.map(payroll => {
        payroll.name = `NÓMINA ${payroll.month.toUpperCase()} ${
          payroll.year
        }`.toUpperCase()
        return payroll
      })
    },
  },
  actions: {
    fetchPayrolls: async ({ commit }) => {
      commit('setPayrolls', await fetchAllPayrolls())
    },
    fetchPayrollById: async ({ commit }, payrollId) => {
      const payroll = await fetchPayrollById(payrollId)
      commit('setPayroll', payroll)
    },
    fetchUserPayrolls: async ({ commit }) => {
      const payrolls = await fetchUserPayrolls()
      commit('setPayrolls', payrolls)
    },
  },
  getters: {},
}
