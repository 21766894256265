export const parseDates = entity => {
  const parsedEntity = { ...entity }

  parsedEntity.seenDate = new Date(parsedEntity.seenDate)
  parsedEntity.confirmationDate = new Date(parsedEntity.confirmationDate)
  if (parsedEntity.contractEndDate) {
    parsedEntity.contractEndDate = new Date(parsedEntity.contractEndDate)
  }
  if (parsedEntity.contractStartDate) {
    parsedEntity.contractStartDate = new Date(parsedEntity.contractStartDate)
  }
  return parsedEntity
}
