import moment from 'moment'
import 'moment/locale/es'

moment.locale('es')

export const getCurrentHour = () => moment().format('HH:mm')

export const formatDateToHour = date => moment(date).format('HH:mm')

export const formatDateToHourWithSeconds = date =>
  moment(date).format('HH:mm:ss')

export const getCurrentDate = () => moment().format('HH:mm:ss')

export const getCurrentDateTime = () => moment().format('DD/MM/YYYY - HH:mm')

export const formatDateForDatepicker = date => moment(date).format('YYYY-MM-DD')
export const formatDateISO = () => moment().format('YYYY-MM-DD')

export const formatDateToDate = date => moment(date).format('DD/MM/YYYY')

export const formatDateToDateTime = date =>
  moment(date).format('DD/MM/YYYY - HH:mm')

export const formatDateToDateTimeWithSeconds = date =>
  moment(date).format('DD/MM/YYYY - HH:mm:ss')

export const parseDate = (stringDate, format) =>
  moment(stringDate, format).toDate()

export const formatHourForHourpicker = date => moment(date).format('HH:mm')
