import {
  formatDateToDate,
  formatDateToDateTime,
  formatDateToDateTimeWithSeconds,
  formatDateToHour,
  formatDateToHourWithSeconds,
} from '@/services/time'
import moment from 'moment'
import Vue from 'vue'

moment.locale('es')

Vue.filter('toDate', function (value) {
  if (value) {
    return formatDateToDate(value)
  }
})

Vue.filter('toDateTime', function (value) {
  if (value) {
    return formatDateToDateTime(value)
  }
})

Vue.filter('toDateTimeWithSeconds', function (value) {
  if (value) {
    return formatDateToDateTimeWithSeconds(value)
  }
})

Vue.filter('toTime', function (value) {
  if (value) {
    return formatDateToHour(value)
  }
})

Vue.filter('toTimeWithSeconds', function (value) {
  if (value) {
    return formatDateToHourWithSeconds(value)
  }
})
