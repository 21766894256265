<template>
  <b-container fluid>
    <b-row>
      <b-card :class="[`card-info`]" align-self="center" class="w-100">
        <b-card-title class="mt-2">
          <b-container fluid>
            <b-row align-h="between">
              <b-col>
                <h2>Subir documento</h2>
              </b-col>
            </b-row>
          </b-container>
        </b-card-title>
        <b-card-body>
          <b-form>
            <b-form-group label="Nombre" label-size="lg" label-class="pl-0">
              <b-input v-model="form.name" minlength="5" required />
            </b-form-group>
            <b-form-group
              label="Descripción"
              label-size="lg"
              label-class="pl-0"
            >
              <b-form-textarea
                v-model="form.description"
                placeholder="Introduce la descripcion"
                minlength="5"
                type="textarea"
                autosize
              />
            </b-form-group>
            <b-form-group
              label="Añadir Documento"
              label-size="lg"
              label-class="pl-0"
            >
              <input
                ref="inpload"
                class="upload"
                type="file"
                :name="nameFile()"
                @change="
                  setFileInfo({
                    fileName: $event.target.name,
                    fileList: $event.target.files,
                    uploaded: false,
                  })
                "
              />
            </b-form-group>
          </b-form>
        </b-card-body>
        <template slot="footer">
          <b-row align-h="end">
            <b-button variant="danger" @click="close()"> Cancelar </b-button>
            <b-button
              variant="primary"
              class="ml-3"
              @click="uploadStateDocument()"
            >
              Subir documento
            </b-button>
          </b-row>
        </template>
      </b-card>
    </b-row>
  </b-container>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { errorOnFileUpload } from '@/services/messageService'
export default {
  name: 'UploadDocument',
  data() {
    return {
      file: '',
      form: { name: '', description: '', date: undefined },
    }
  },
  methods: {
    ...mapMutations('documentation', ['setFileInfo']),
    ...mapActions('documentation', ['uploadDocument', 'resetFileUpload']),
    nameFile: function () {
      const dateTime = Date.now()
      return `call_${Math.floor(dateTime / 1000)}`
    },
    async uploadStateDocument() {
      try {
        await this.uploadDocument(this.form)
        this.resetFileUpload()
        this.$refs.inpload.value = null
        this.form = { name: '', description: '', date: undefined }
        this.close()
      } catch (error) {
        errorOnFileUpload({ context: this, error })
      }
    },
    close() {
      this.$router.push({
        path: '/documentacion',
      })
    },
  },
}
</script>
