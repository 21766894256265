<template>
  <b-row>
    <b-col v-for="item in itemsToShow" :key="item.title" cols="12" class="mb-2">
      <b-card no-body>
        <b-card-title class="mb-0">
          <b-container fluid class="mt-3">
            <b-row>
              <b-col cols="12">
                <h2 style="text-align: center">
                  <span>
                    <i class="fas" :class="`fa-${item.icon}`" />
                  </span>
                  {{ item.title }}
                </h2>
              </b-col>
            </b-row>
          </b-container>
        </b-card-title>
        <b-card-body>
          <b-row>
            <b-col>
              <b-button :to="item.path" block variant="primary" size="lg">
                {{ item.buttonText }}
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { canAccess } from '@/guard/auth.guard'
export default {
  name: 'MainMenu',
  props: {
    items: { type: Array, default: () => [] },
  },
  computed: {
    itemsToShow() {
      return this.items.filter(canAccess)
    },
  },
}
</script>
