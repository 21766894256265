<template>
  <b-container fluid>
    <b-card v-if="user" no-body :class="[`card-info`]" align-self="center">
      <b-card-title class="mt-2">
        <b-container fluid>
          <b-row align-h="between">
            <b-col class="mt-3 ml-3">
              <h2>Mi perfil</h2>
            </b-col>
          </b-row>
        </b-container>
      </b-card-title>
      <b-card-body class="mt-0 pt-0">
        <b-row align-h="center">
          <b-col class="mt-3 ml-3">
            <b-form-group
              :label="user.partner ? 'Apellidos Nombre' : 'Nombre Apellidos'"
              label-size="lg"
              label-class="pl-0"
            >
              <b-form-input disabled :value="user.name" />
            </b-form-group>
            <!--<b-form-group
              label="Apellidos"
              label-size="lg"
              label-class="pl-0"
            >
              <b-form-input
                :value="userFullName.lastName"
              />
            </b-form-group>-->
            <b-form-group label="DNI" label-size="lg" label-class="pl-0">
              <b-form-input disabled :value="user.nif" />
            </b-form-group>
            <b-form-group label="Teléfono" label-size="lg" label-class="pl-0">
              <b-form-input disabled :value="user.phoneNumber" />
            </b-form-group>
            <b-form-group label="Email" label-size="lg" label-class="pl-0">
              <b-form-input disabled :value="user.email" />
            </b-form-group>
            <b-form-group label="Dirección" label-size="lg" label-class="pl-0">
              <b-form-input disabled :value="user.address" />
            </b-form-group>

            <b-button
              variant="warning"
              class="change-personal-data-button"
              @click="showProfileChangeMessage"
            >
              Cambiar datos
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card v-else> Datos de usuario no encontrados </b-card>
  </b-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
//import { getMyProfile } from '@/services/authService'
import { infoOnProfileChange } from '@/services/messageService'

export default {
  name: 'MyProfile',
  data() {
    return {
      isLoading: true,
      centerDialogVisible: false,
      /*user: {
        name: '',
        nif: '',
        address: '',
        phoneNumber: '',
        email: '',
      },*/
    }
  },
  computed: {
    ...mapState('auth', ['user', 'userId']),
    userFullName() {
      if (!this.user) return {}
      const nameWords = this.user.name.split(' ')
      const numberOfWords = nameWords.length
      const name = {
        firstName: '',
        lastName: '',
      }
      if (this.user.partner) {
        name.lastName = `${nameWords[0]} ${nameWords[1]}`
        for (let i = 2; i < numberOfWords; i++) {
          name.firstName += ` ${nameWords[i]}`
        }
        return name
      } else {
        name.firstName = nameWords[0]
        if (nameWords.length > 1) {
          name.lastName = nameWords.slice(1).join(' ')
        }
        return name
      }
    },
  },
  async mounted() {
    /*const profile = await getMyProfile()
    if (profile && profile.id) {
      this.user = profile
    }*/
    await this.loadUser()
    this.isLoading = false
  },
  methods: {
    ...mapActions('auth', ['loadUser']),
    showProfileChangeMessage: function () {
      infoOnProfileChange({ context: this })
    },
  },
}
</script>

<style lang="scss" scoped>
.change-personal-data-button {
  float: right;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
