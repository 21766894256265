import { saveAuthData } from '@/persistence/sessionStorage'
import { httpApi } from '@/services/http/httpApi'
import { httpAuth } from '@/services/http/httpAuth'

export const login = async user => {
  try {
    const response = await httpApi.post('/auth/login', user)
    if (response.status === 201) {
      const { token, userId, name, roles } = response.data
      const authData = {
        token,
        userId,
        name,
        roles,
      }
      saveAuthData(authData)
      return true
    }
    return false
  } catch (err) {
    return false
  }
}

export const checkRecoveryData = async recovery => {
  try {
    const response = await httpApi.post('/auth/recovery', recovery)
    return response.status === 201 ? response.data : null // userId
  } catch (err) {
    return null
  }
}

export const verifyRecoveryToken = async token => {
  try {
    const response = await httpApi.get(`/auth/recovery/token/${token}`)
    return response.status === 200 ? response.data : false
  } catch (err) {
    return false
  }
}

export const changePassword = async password => {
  try {
    const response = await httpAuth.put('/users/change_pass', password)
    return response.status === 200
  } catch (err) {
    return false
  }
}

export const changePasswordByToken = async password => {
  try {
    const response = await httpApi.put('/auth/password', password)
    return response.status === 200
  } catch (err) {
    return false
  }
}

export const getMyProfile = async () => {
  try {
    const response = await httpApi.get('/users/profile')
    if (response.status === 200) {
      return response.data
    }
  } catch (err) {
    return null
  }
}
