<template>
  <b-card no-body class="mb-3" align-self="center">
    <b-card-title class="mt-2">
      <b-container fluid>
        <b-row align-h="between">
          <b-col cols="12">
            <h3>
              Certificado I.R.P.F. de {{ withholding.role }}
              {{ withholding.year }}
            </h3>
          </b-col>
        </b-row>
      </b-container>
    </b-card-title>
    <b-card-body>
      <b-row>
        <b-col md="8" xs="12">
          <b-card-text v-if="withholding.extra">
            {{ withholding.extra.join('\n') }}
          </b-card-text>
        </b-col>
        <b-col md="4" xs="12">
          <b-button size="lg" block variant="primary" @click="openPdf()">
            Ver certificado
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>
<script>
import { openFile } from '@/services/filesService.js'
import { errorOnFileLoad } from '@/services/messageService'

export default {
  name: 'WithholdingCard',
  props: {
    withholding: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    async openPdf() {
      try {
        const fileProperties = {
          entity: 'withholding',
          entityID: this.withholding.id,
          name: this.withholding.name,
        }
        await openFile(fileProperties)
      } catch (error) {
        errorOnFileLoad({ context: this, error })
      }
    },
  },
}
</script>
<style></style>
