<template>
  <footer class="footer">
    <b-container fluid>
      <b-row>
        <b-col>
          <div>
            <p class="text-center text-muted">&copy; SAT LA PLANA 9422</p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>
<script>
export default {}
</script>
<style scoped>
.footer {
  /*position: absolute;
  bottom: 0;*/
  width: 100%;
  height: 60px; /* Set the fixed height of the footer here */
  line-height: 60px; /* Vertically center the text there */
  background-color: #f5f5f5;
}

/* Custom page CSS
-------------------------------------------------- */
/* Not required for template or sticky footer method. */

.container {
  width: auto;
  max-width: 680px;
  padding: 0 15px;
}
</style>
