import axios from 'axios'
import { cleanSession, loadToken } from '@/persistence/sessionStorage'
export const httpApi = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 15000,
})

httpApi.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response.status === 401) {
      cleanSession()
      window.location = '/#/login'
    } else {
      return Promise.reject(error)
    }
  }
)

httpApi.interceptors.request.use(
  async config => {
    config.headers = Object.assign(
      { 'x-access-token': loadToken() },
      config.headers
    )
    return config
  },
  error => Promise.reject(error)
)
