<template>
  <b-container fluid>
    <b-overlay :show="isLoading">
      <control-list-bar
        @searchChange="newSearch => (searchedText = newSearch)"
      />
      <div id="containlist">
        <template v-if="products.length">
          <b-row v-for="product in filteredProducts" :key="product.id">
            <b-col align-self="center">
              <product-card :product="product" />
            </b-col>
          </b-row>
        </template>
        <b-row v-else>
          <b-col cols="12" align-self="center">
            <p class="text-center font-weight-bold bg-light text-uppercase">
              No hay productos disponibles para la plaga seleccionada
              {{ treatmentCreated.plague.descripcion }}
            </p>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </b-container>
</template>
<script>
import names from '@/config/names'
import { mapState, mapActions } from 'vuex'
import ControlListBar from '@/components/search/ControlListBar'
import ProductCard from '@/components/cards/TreatmentProductCard'

export default {
  name: 'ProductList',
  components: {
    ProductCard,
    ControlListBar,
  },
  data() {
    return {
      searchedText: '',
      names,
      isLoading: true,
    }
  },
  computed: {
    ...mapState('treatments', ['treatmentCreated', 'products']),
    filteredProducts() {
      const filteredProducts = this.products.filter(product => {
        for (const property in product) {
          if (
            typeof product[property] === 'string' &&
            product[property]
              .toLowerCase()
              .includes(this.searchedText.toLowerCase())
          ) {
            return true
          }
        }
        return false
      })
      // .sort(
      //   (a, b) => new Date(b.fechaaplicacion) - new Date(a.fechaaplicacion)
      // )
      return filteredProducts
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    ...mapActions('treatments', ['fetchProducts']),
    loadData: async function () {
      // console.log('En loaddata Product ' + this.treatmentCreated.plague)
      // await this.fetchProducts()
      this.isLoading = false
    },
  },
}
</script>

<style scoped>
div#containlist {
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}
@media screen and (max-width: 380px) {
  div#containlist {
    height: 450px;
  }
}
</style>
