import DocumentsMenu from '@/components/menus/DocumentsMenu'
import MainMenu from '@/components/menus/MainMenu'
import { canAccess } from '@/guard/auth.guard'
import DashboardLayout from '@/layout/DashboardLayout.vue'
import EmptyLayout from '@/layout/EmptyLayout'
import AttendeeCallDetails from '@/pages/calls/attendee/AttendeeCallDetails'
import AttendeeCallList from '@/pages/calls/attendee/AttendeeCallList'
import Docs from '@/pages/docs/DocList'
import UploadDocument from '@/pages/docs/UploadDocument'
import Login from '@/pages/Login'
import NavisionPage from '@/pages/NavisionPage'
import NotFound from '@/pages/NotFoundPage.vue'
import RecipientNoticeDetails from '@/pages/notices/recipient/RecipientNoticeDetails'
import RecipientsNoticeList from '@/pages/notices/recipient/RecipientsNoticeList'
import RecipientContractDetails from '@/pages/contract/RecipientContractDetails'
import RecipientsContractList from '@/pages/contract/RecipientContractList'
import SenderNoticeDetails from '@/pages/notices/sender/SenderNoticeDetails'
import SenderNoticeList from '@/pages/notices/sender/SenderNoticeList'
import SenderNoticeSave from '@/pages/notices/sender/SenderNoticeSave'
import Payrolls from '@/pages/payrolls/PayrollList'
import Unauthorized from '@/pages/Unauthorized'
import MyProfile from '@/pages/users/MyProfile'
import SignOut from '@/pages/users/SignOut'
import Users from '@/pages/users/Users'
import Withholdings from '@/pages/withholdings/WithholdingList'
import TreatmentList from '@/pages/treatments/TreatmentList'
import TreatmentCreateCore from '@/pages/treatments/TreatmentCreateCore'
import TreatmentCreatePPS from '@/pages/treatments/TreatmentCreatePPS'

export const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    hidden: true,
  },
  {
    path: '/cerrar-sesion',
    name: 'Cerrando sesion',
    component: SignOut,
    hidden: true,
  },
  {
    path: '/',
    name: 'main',
    component: DashboardLayout,
    main: true,
    meta: {
      title: 'main',
      icon: 'home',
      roles: [
        'adm720',
        'employee',
        'removedEmployee',
        'partner',
        'removedPartner',
        'owner',
        'removedOwner',
        'runner',
      ],
    },
    redirect: '/menu-principal',
    children: [
      {
        path: 'menu-principal',
        name: 'principal',
        component: MainMenu,
        meta: {
          title: 'main',
          icon: 'home',
          roles: [
            'adm720',
            'employee',
            'removedEmployee',
            'partner',
            'removedPartner',
            'owner',
            'removedOwner',
            'runner',
          ],
        },
      },
      {
        path: 'menu-documentos',
        name: 'Documentos',
        component: DocumentsMenu,
        meta: {
          title: 'docs-menu',
          icon: 'file-alt',
          roles: [
            'adm720',
            'employee',
            'removedEmployee',
            'partner',
            'removedPartner',
            'owner',
            'removedOwner',
          ],
        },
      },
      {
        path: 'sin-permiso',
        name: 'Sin permiso',
        component: Unauthorized,
        hidden: true,
      },
      {
        path: 'mi-perfil',
        name: 'Mi perfil',
        component: MyProfile,
        hidden: true,
        meta: {
          title: 'main',
          icon: 'home',
          roles: [
            'adm720',
            'employee',
            'removedEmployee',
            'partner',
            'removedPartner',
            'owner',
            'removedOwner',
            'runner',
          ],
        },
      },
      {
        path: 'nominas',
        name: 'Nóminas',
        component: Payrolls,
        hidden: true,
        meta: {
          sub: true,
          title: 'payroll',
          icon: 'money-bill',
          roles: ['adm720', 'employee', 'removedEmployee'],
        },
      },
      {
        path: 'certificados-irpf',
        name: 'Certificados I.R.P.F',
        component: Withholdings,
        hidden: true,
        meta: {
          sub: true,
          title: 'withholdings',
          icon: 'hand-holding-usd',
          roles: [
            'adm720',
            'employee',
            'removedEmployee',
            'owner',
            'removedOwner',
          ],
        },
      },
      {
        path: 'documentacion',
        component: EmptyLayout,
        hidden: true,
        meta: {
          sub: true,
          title: 'docs',
          icon: 'file-upload',
          roles: [
            'adm720',
            'employee',
            'removedEmployee',
            'partner',
            'removedPartner',
            'owner',
            'removedOwner',
          ],
        },
        children: [
          {
            path: '',
            name: 'Documentacion',
            component: Docs,
            props: true,
            hidden: true,
            meta: {
              title: 'docs',
              icon: 'file',
              roles: [
                'adm720',
                'employee',
                'removedEmployee',
                'partner',
                'removedPartner',
                'owner',
                'removedOwner',
              ],
            },
          },

          {
            path: 'subir',
            name: 'Subir documento',
            component: UploadDocument,
            props: true,
            hidden: true,
            meta: {
              title: 'docs',
              icon: 'file',
              roles: [
                'adm720',
                'employee',
                'removedEmployee',
                'partner',
                'removedPartner',
                'owner',
                'removedOwner',
              ],
            },
          },
        ],
      },
      {
        path: 'comunicaciones',
        name: 'Comunicaciones',
        component: EmptyLayout,
        meta: {
          title: 'notices',
          icon: 'paper-plane',
          roles: [
            'adm720',
            'employee',
            'removedEmployee',
            'partner',
            'removedPartner',
            'owner',
            'removedOwner',
          ],
        },
        children: [
          {
            path: '',
            name: 'Comunicaciones',
            component: RecipientsNoticeList,
            hidden: true,
            meta: {
              title: 'notices',
              icon: 'paper-plane',
              roles: [
                'adm720',
                'employee',
                'removedEmployee',
                'partner',
                'removedPartner',
                'owner',
                'removedOwner',
              ],
            },
          },
          {
            path: ':id/detalle',
            name: 'Comunicación',
            component: RecipientNoticeDetails,
            props: true,
            hidden: true,
            meta: {
              title: 'notices',
              icon: 'handshake',
              roles: [
                'adm720',
                'employee',
                'removedEmployee',
                'partner',
                'removedPartner',
                'owner',
                'removedOwner',
              ],
            },
          },
        ],
      },
      {
        path: 'contratos',
        name: 'Contratos',
        component: EmptyLayout,
        meta: {
          title: 'contracts',
          icon: 'file',
          roles: [
            'adm720',
            'employee',
            'removedEmployee',
            'partner',
            'removedPartner',
            'owner',
            'removedOwner',
          ],
        },
        children: [
          {
            path: '',
            name: 'Contratos',
            component: RecipientsContractList,
            hidden: true,
            meta: {
              title: 'contracts',
              icon: 'file',
              roles: [
                'adm720',
                'employee',
                'removedEmployee',
                'partner',
                'removedPartner',
                'owner',
                'removedOwner',
              ],
            },
          },
          {
            path: ':id/detalle',
            name: 'Contrato',
            component: RecipientContractDetails,
            props: true,
            hidden: true,
            meta: {
              title: 'contracts',
              icon: 'file',
              roles: [
                'adm720',
                'employee',
                'removedEmployee',
                'partner',
                'removedPartner',
                'owner',
                'removedOwner',
              ],
            },
          },
        ],
      },
      {
        path: 'convocatorias',
        name: 'Convocatorias',
        component: EmptyLayout,
        meta: {
          title: 'calls',
          icon: 'bell',
          roles: [
            'adm720',
            'employee',
            'removedEmployee',
            'partner',
            'removedPartner',
            'owner',
            'removedOwner',
          ],
        },
        children: [
          {
            path: '',
            name: 'Convocatorias',
            component: AttendeeCallList,
            hidden: true,
            meta: {
              title: 'calls',
              icon: 'paper-plane',
              roles: [
                'adm720',
                'employee',
                'removedEmployee',
                'partner',
                'removedPartner',
                'owner',
                'removedOwner',
              ],
            },
          },
          {
            path: ':id/detalle',
            name: 'Convocatoria',
            component: AttendeeCallDetails,
            props: true,
            hidden: true,
            meta: {
              title: 'calls-details',
              icon: 'handshake',
              roles: [
                'adm720',
                'employee',
                'removedEmployee',
                'partner',
                'removedPartner',
                'owner',
                'removedOwner',
              ],
            },
          },
        ],
      },
      {
        path: 'tratamientos',
        name: 'Tratamientos',
        component: EmptyLayout,
        meta: {
          title: 'treatments',
          icon: 'bong',
          roles: ['adm720', 'owner', 'removedOwner', 'runner'],
        },
        children: [
          {
            path: '',
            name: 'Tratamientos',
            component: TreatmentList,
            hidden: true,
            meta: {
              title: 'treatments',
              icon: 'bong',
              roles: ['adm720', 'owner', 'removedOwner', 'runner'],
            },
          },
          {
            path: '/crear',
            name: 'Crear Tratamientos',
            component: TreatmentCreateCore,
            props: true,
            hidden: true,
            meta: {
              title: 'plot-list',
              icon: 'bong',
              roles: ['adm720', 'owner', 'removedOwner', 'runner'],
            },
          },
          {
            path: '/pps',
            name: 'Aplicación PPS',
            component: TreatmentCreatePPS,
            props: true,
            hidden: true,
            meta: {
              title: 'pps-list',
              icon: 'bong',
              roles: ['adm720', 'owner', 'removedOwner', 'runner'],
            },
          },
        ],
      },
      {
        path: 'acceso-navision',
        name: 'Acceso Navision',
        component: NavisionPage,
        meta: {
          title: 'navision',
          icon: 'external-link-alt',
          roles: ['adm720', 'runner'],
        },
      },
      {
        path: 'admin',
        name: 'admin',
        component: EmptyLayout,
        meta: { title: 'admin', icon: 'user-lock', roles: ['adm720'] },
        children: [
          {
            path: 'comunicaciones',
            component: EmptyLayout,
            meta: {
              title: 'admin-notices',
              icon: 'user-shield',
              roles: ['adm720'],
            },
            children: [
              {
                path: '',
                name: 'Gestión administrador',
                component: SenderNoticeList,
                props: true,
                meta: {
                  admin: true,
                  title: 'notices',
                  icon: 'handshake',
                  roles: ['adm720'],
                },
              },
              {
                path: 'crear',
                name: 'Crear comunicacion',
                component: SenderNoticeSave,
                props: true,
                meta: {
                  admin: true,
                  title: 'notices',
                  icon: 'handshake',
                  roles: ['adm720'],
                },
              },
              {
                path: ':id',
                name: 'Detalle comunicacion administrador',
                component: SenderNoticeDetails,
                props: true,
                meta: {
                  admin: true,
                  title: 'notices',
                  icon: 'handshake',
                  roles: ['adm720'],
                },
              },
              {
                path: ':id/editar',
                name: 'Editar comunicacion',
                component: SenderNoticeSave,
                props: true,
                meta: {
                  admin: true,
                  title: 'notices',
                  icon: 'handshake',
                  roles: ['adm720'],
                },
              },
            ],
          },
          {
            path: 'usuarios',
            name: 'Usuarios',
            component: Users,
            meta: {
              admin: true,
              title: 'admin-users',
              icon: 'users',
              roles: ['adm720'],
            },
          },
        ],
      },
    ],
  },
  { path: '*', component: NotFound }, // If no match, redirecto to /
]

export const generateSideMenu = () =>
  routes
    .find(el => el.main)
    .children.filter(el => !el.hidden)
    .filter(el => canAccess(el))
    .map(menu => {
      if (!menu.path.startsWith('/') && !menu.path.startsWith('*')) {
        const path = '/' + menu.path
        menu.path = path
      }

      if (menu.children) {
        const children = menu.children
          .filter(el => !el.hidden)
          .filter(el => canAccess(el))
          .map(submenu => {
            const path = menu.path + '/' + submenu.path
            return { ...submenu, path }
          })
        if (children.length > 0) {
          return children
        }
      }
      return menu
    })
    .flat()
