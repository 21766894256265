export const errorMessage = ({ context, message, title }) => {
  context.$bvModal.msgBoxOk(message, {
    title,
    centered: true,
  })
}

export const infoMessage = ({ context, message, title }) => {
  context.$bvModal.msgBoxOk(message, {
    title,
    centered: true,
  })
}

export const successMessage = ({ context, message, title }) => {
  context.$bvToast.toast(message, {
    title,
    variant: 'success',
    toaster: 'b-toaster-top-center',
    noCloseButton: true,
    autoHideDelay: 5000,
  })
}

export const confirmMessage = ({ context, message, title }) => {
  return context.$bvModal.msgBoxConfirm(message, {
    title,
    okVariant: 'danger',
    okTitle: 'Si',
    cancelTitle: 'No',
    footerClass: 'p-2',
    hideHeaderClose: false,
    centered: true,
  })
}

export const infoOnProfileChange = ({ context }) => {
  infoMessage({
    context,
    message:
      'Si desea cambiar sus datos personales debe contactar con el administrador de la aplicación: admon@bagu.com',
    title: 'Atención',
  })
}

export const errorOnPasswordChangeInvalid = ({ context }) => {
  errorMessage({
    context,
    message: 'Los datos no coinciden',
    title: 'Error',
  })
}
export const errorOnPasswordChangeFieldsNeeded = ({ context }) => {
  errorMessage({
    context,
    message: 'Tanto el usuario como la constraseña son obligatorios',
    title: 'Error',
  })
}

export const errorOnLoginRecoveryFieldsNotMatch = ({ context }) => {
  errorMessage({
    context,
    message: 'Los datos no coinciden',
    title: 'Error',
  })
}
export const errorOnLoginRecoveryFieldsNeeded = ({ context }) => {
  errorMessage({
    context,
    message: 'Se necesitan ambos campos para la recuperación de la contraseñan',
    title: 'Error',
  })
}

export const errorOnLoginRecoveryCheckingData = ({ context, error }) => {
  errorMessage({
    context,
    message: `Fallo a la hora de comprobar los datos, contacte con el administrador: ${error}`,
    title: 'Error',
  })
}

export const errorOnLogin = ({ context, error }) => {
  errorMessage({
    context,
    message: `Ha ocurrido un error durante el inicio de sesion, contacte con el administrador: ${error}`,
    title: 'Error',
  })
}

export const errorOnLoginInvalid = ({ context }) => {
  errorMessage({
    context,
    message:
      'Las credenciales proporcionadas no son correctas, revíselas y vuelva a intentarlo',
    title: 'Error',
  })
}

export const errorOnLoginEmptyFields = ({ context }) => {
  errorMessage({
    context,
    message:
      'Tanto el usuario como la constraseña son obligatorios, por favor introduzca unas credenciales',
    title: 'Error',
  })
}

export const errorOnLogout = ({ context, error }) => {
  errorMessage({
    context,
    message: `Ha ocurrido un error durante el cierre de sesión, contacte con el administrador: ${error}`,
    title: 'Error',
  })
}
export const errorOnFileLoad = ({ context, error }) => {
  errorMessage({
    context,
    message: `Ha ocurrido un error cargando el documento, contacte con el administrador:  ${error}`,
    title: 'Error',
  })
}

export const errorOnFileUpload = ({ context, error }) => {
  errorMessage({
    context,
    message: `Ha ocurrido un error subiendo el documento, contacte con el administrador:  ${error}`,
    title: 'Error',
  })
}

export const errorOnDataLoad = ({ context, error }) => {
  errorMessage({
    context,
    message: `Ha ocurrido un error cargando los datos, contacte con el administrador:  ${error}`,
    title: 'Error',
  })
}

export const errorOnDataSave = ({ context, error }) => {
  errorMessage({
    context,
    message: `Ha ocurrido un error guardando los datos, contacte con el administrador:  ${error}`,
    title: 'Error',
  })
}

export const errorOnDataDelete = ({ context, error }) => {
  errorMessage({
    context,
    message: `Ha ocurrido un error borrando los datos, contacte con el administrador:  ${error}`,
    title: 'Error',
  })
}

export const successOnDataSave = ({ context }) => {
  successMessage({
    context,
    message: 'Los datos se han guardado correctamente',
  })
}

export const successOnDataDelete = ({ context }) => {
  successMessage({
    context,
    message: 'Los datos se han borrado correctamente',
  })
}
