<template>
  <div class="login-background">
    <div class="login">
      <div class="login-logo">
        <center>
          <img
            class="login-logo-image"
            src="../assets/img/logotop.png"
            alt="Formato no soportado"
          />
        </center>
      </div>
      <div class="login-title">
        <center>
          <div class="small">Bienvenido a</div>
          <div class="large">
            INTRANET <br />
            SAT LA PLANA 9422
          </div>
        </center>
      </div>
      <b-container class="login-footer">
        <login-form v-if="mode === 'login'" />
        <initial-recovery-form v-else-if="mode === 'recovery'" />
        <password-change v-else-if="mode === 'password'" />
      </b-container>
      <div>
        <div class="bottom-footer">
          <footer>
            <b-link
              type="outline-primary"
              class="gdpr-link"
              @click="openGDPR()"
            >
              Politica de privacidad
            </b-link>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from '@/components/forms/login/LoginForm.vue'
import InitialRecoveryForm from '@/components/forms/login/InitialRecoveryForm.vue'
import PasswordChange from '@/components/forms/login/PasswordChange.vue'
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'Login',
  components: { LoginForm, InitialRecoveryForm, PasswordChange },
  data() {
    return {
      authenticated: false,
    }
  },
  computed: {
    ...mapState('auth', ['mode']),
  },
  mounted() {
    this.setMode('login')
  },
  methods: {
    ...mapMutations('auth', ['setMode']),
    openGDPR() {
      window.open('/gdpr.pdf')
    },
    goTo(url) {
      window.location = url
    },
  },
}
</script>

<style scoped>
.login-logo {
  margin-top: 20%;
  margin-bottom: 10%;
}

.login-background {
  width: 100vw;
  height: 100vh;
  background-image: url('../assets/img/fondo.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-image {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 75%;
  height: 100%;
  min-width: 731px;
  min-height: 487px;
}

.login {
  position: absolute;
  right: 0px;
  /*margin-top: -8px;*/
  width: 25%;
  min-width: 350px;
  height: 100vh;
  background-color: #222222;
}

.login-background {
  width: 100vw;
  height: 100vh;
}

.login-title .small {
  font-size: 1rem;
  color: #e5e5e5;
}

.login-title .large {
  font-size: 2.5rem;
  font-family: 'Montserrat', sans-serif;
  color: #e5e5e5;
  margin-top: 5%;
}

.bottom-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #222;
}

.login-footer {
  width: 100%;
  color: whitesmoke;
  height: 25px;
}

.gdpr-link {
  float: right;
  padding-right: 10px;
}

.bottom-button-bar {
  /*padding-top: 30px;*/
  position: absolute;
  bottom: 5px;
  margin-left: 10%;
}

@media (max-height: 720px) {
  .login-logo {
    display: none;
  }
}
@media (max-height: 500px) {
  .login-title {
    display: none;
  }
}
@media (max-width: 767px) {
  .login-title .large {
    /*display: none;*/
    font-size: 1.5rem;
  }
  .login-logo {
    margin: 5vh 0 0 0; /*display: none;*/
  }
  .login-logo-image {
    max-width: 90vh;
  }
  .bottom-button-bar {
    padding-top: 0;
  }
  .login {
    position: absolute;
    right: 0px;
    /*margin-top: -8px;*/
    width: 100vw;
    min-width: 350px;
    height: 100vh;
    background-color: #222222;
  }
}
</style>
