<template>
  <b-card>
    <b-card-title>
      <h3>{{ pps.plague }}</h3>
    </b-card-title>
    <b-card-text>
      <b-row>
        <b-col md="8" xs="12">
          <h4>{{ pps.pPPS }}</h4>
          <p style="font-size: 80%; color: #666">{{ pps.product }}</p>
        </b-col>
        <b-col md="4" xs="12">
          <h4>{{ pps.pDate }}</h4>
        </b-col>
      </b-row>
    </b-card-text>
    <b-card-footer>
      <b-row>
        <b-col cols="12">
          <b-button class="ml-3" variant="danger" @click="DeletePPS()"
            >ELIMINAR</b-button
          >
        </b-col>
      </b-row>
    </b-card-footer>
  </b-card>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
// import { errorOnDataSave } from '@/services/messageService'
// import { deleteTreatment } from '@/services/treatmentsService'

export default {
  name: 'PPSCard',
  props: {
    pps: {
      type: Object,
      default: () => ({}),
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    ...mapState('treatments', ['treatmentCreated']),
  },
  methods: {
    ...mapMutations('treatments', ['deletePPSTreatmentList']),
    async DeletePPS() {
      console.log('ELIMINAR ' + JSON.stringify(this.pps))
      this.deletePPSTreatmentList(this.pps)
    },
    // close() {
    //   this.$router.push({
    //     path: '/crear',
    //   })
    // },
  },
}
</script>
<style>
.card-body {
  padding: 0.8rem;
}
h3 {
  font-size: 1em;
}
h4 {
  font-size: 1em;
  font-weight: 400;
}
</style>
