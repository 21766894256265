<template>
  <b-container fluid>
    <control-list-bar
      @sortChange="newSortBy => (sortBy = newSortBy)"
      @searchChange="newSearch => (searchedText = newSearch)"
    />
    <template v-if="withholdings.length">
      <b-row v-for="withholding in filteredWithholdings" :key="withholding.id">
        <b-col align-self="center">
          <withholding-card :withholding="withholding" />
        </b-col>
      </b-row>
    </template>
    <b-row v-else>
      <b-col cols="12" align-self="center">
        <p class="text-center font-weight-bold bg-light text-uppercase">
          No hay certificados disponibles aún
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import WithholdingCard from '@/components/cards/WithholdingCard'
import ControlListBar from '@/components/search/ControlListBar'
import { parseDate } from '@/services/time'
export default {
  name: 'WithholdingList',
  components: {
    WithholdingCard,
    ControlListBar,
  },
  data() {
    return {
      searchedText: '',
      file: '',
    }
  },
  computed: {
    ...mapState('withholdings', ['withholdings']),
    filteredWithholdings() {
      return this.withholdings
        .filter(withholding => {
          for (const property of Object.values(withholding)) {
            if (
              typeof property === 'string' &&
              property.toLowerCase().includes(this.searchedText.toLowerCase())
            ) {
              return true
            }
          }
        })
        .map(el => ({ ...el, payrollDate: parseDate(`${el.year}`, 'YYYY') }))
        .sort(
          (a, b) => new Date(b.withholdingDate) - new Date(a.withholdingDate)
        )
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    ...mapActions('withholdings', ['fetchWithholdings']),
    async loadData() {
      await this.fetchWithholdings()
    },
  },
}
</script>

<style scoped>
.el-table {
  width: 100%;
  min-height: 100px;
}

.el-table .warning-row .cell {
  font-weight: bolder;
}
.el-table .warning-row {
  background: oldlace;
}

.search-bar {
  margin-bottom: 15px;
  width: 25%;
}
</style>
