<template>
  <b-container fluid>
    <b-row>
      <b-col align-self="center">
        <contract-card :contract="contract" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ContractCard from '@/components/cards/ContractCard'
import { errorOnDataLoad } from '@/services/messageService'
import { fetchNoticeRecipientById } from '@/services/noticesService'

export default {
  name: 'AttendeeContractDetails',
  components: {
    ContractCard,
  },
  data() {
    return {
      contract: {},
      isLoading: true,
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      try {
        this.contract = await fetchNoticeRecipientById(
          this.$route.params.id,
          'contract'
        )
        this.isLoading = false
      } catch (error) {
        errorOnDataLoad({ context: this, error })
      }
    },
  },
}
</script>

<style scoped></style>
