import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import Vue from 'vue'

Sentry.init({
  dsn: 'https://80f542939d434858949c0d5f464ff87e@sentry.io/5189970',
  integrations: [
    new Integrations.Vue({
      Vue,
      attachProps: true,
      logErrors: process.env.NODE_ENV !== 'production',
    }),
  ],
})
