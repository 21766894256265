<template>
  <b-col>
    <button-menu :items="menu" />
    <b-button variant="secondary" class="d-md-none" to="/menu-principal" block>
      Volver
    </b-button>
  </b-col>
</template>

<script>
import Menu from '@/components/menus/Menu'
export default {
  name: 'DocumentsMenu',
  components: {
    'button-menu': Menu,
  },
  data: function () {
    return {
      menu: [
        {
          title: 'Nóminas',
          buttonText: 'Ver nóminas',
          path: '/nominas',
          icon: 'money-bill-alt',
          meta: {
            roles: ['adm720', 'employee'],
          },
        },
        {
          title: 'Certificados I.R.P.F',
          buttonText: 'Ver certificados I.R.P.F',
          path: '/certificados-irpf',
          icon: 'hand-holding-usd',
          meta: {
            roles: ['adm720', 'employee', 'owner'],
          },
        },
        {
          title: 'Contratos',
          buttonText: 'Ver contratos',
          path: '/contratos',
          icon: 'file',
          meta: {
            roles: ['adm720', 'employee', 'owner', 'partner'],
          },
        },
        {
          title: 'Otros documentos',
          buttonText: 'Ver otros documentos',
          path: '/documentacion',
          icon: 'file-upload',
          meta: {
            roles: ['adm720', 'employee', 'partner', 'owner'],
          },
        },
      ],
    }
  },
}
</script>
