<template>
  <b-card
    :bg-variant="treatmentCreated.plot.id === plot.id ? 'primary' : 'light'"
    :text-variant="treatmentCreated.plot.id === plot.id ? 'white' : 'black'"
    @click="selectedCard()"
  >
    <b-card-title>
      <h3>{{ plot.paleta }} {{ plot.campanya }}</h3>
    </b-card-title>
    <b-card-text>
      <h4>{{ plot.termino }}<br />{{ plot.partida }}</h4>
    </b-card-text>
    <b-card-footer v-if="runnercode">
      <h4>{{ plot.nombrepropietario }}</h4>
    </b-card-footer>
  </b-card>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
// import { errorOnDataSave } from '@/services/messageService'
// import { deleteTreatment } from '@/services/treatmentsService'

export default {
  name: 'PlotCard',
  props: {
    plot: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('treatments', ['treatmentCreated', 'ownercode', 'runnercode']),
  },
  methods: {
    ...mapMutations('treatments', ['setPlot']),
    selectedCard() {
      this.setPlot(this.plot)
      console.log('SELECTED PLOT ' + this.plot.paleta)
      // this.refresh
      // this.close()
    },
    // close() {
    //   this.$router.push({
    //     path: '/crear',
    //   })
    // },
  },
}
</script>
<style>
.card-body {
  padding: 0.8rem;
}
h3 {
  font-size: 1em;
}
h4 {
  font-size: 1em;
  font-weight: 400;
}
</style>
