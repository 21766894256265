import { httpApi } from '@/services/http/httpMiApi'
// import { parseDates } from './parser'
// import { userQueryBuilder } from './usersService'

export const getActiveCampain = async () => {
  try {
    //
    const response = await httpApi.get('/activecampain')
    // const response = await httpApi.get('/campanyactiva')
    if (response.status < 400) {
      return response.data
    }
  } catch (err) {
    return null
  }
}
export const fetchCampains = async () => {
  try {
    console.log('FETCH -CAMPAINS')
    const response = await httpApi.get('/campains')
    if (response.status < 400) {
      return response.data
    }
  } catch (err) {
    return null
  }
}
export const fetchTreatments = async ownercampain => {
  try {
    console.log('FETCH - TREATMENTS: ' + ownercampain)
    const response = await httpApi.get('/treatments/' + ownercampain)
    if (response.status < 400) {
      return response.data
    }
  } catch (err) {
    return null
  }
}
export const fetchTreatmentsRunner = async runnercampain => {
  try {
    console.log('FETCH - TREATMENTS: ' + runnercampain)
    const response = await httpApi.get('/treatmentsrunner/' + runnercampain)
    if (response.status < 400) {
      return response.data
    }
  } catch (err) {
    return null
  }
}
export const fetchPlots = async ownercampain => {
  try {
    console.log('FETCH - PLOTS: ' + ownercampain)
    const response = await httpApi.get('/plots/' + ownercampain)
    if (response.status < 400) {
      return response.data
    }
  } catch (err) {
    return null
  }
}
export const fetchPlotsRunner = async runnercampain => {
  try {
    console.log('FETCH - PLOTS: ' + runnercampain)
    const response = await httpApi.get('/plotsrunner/' + runnercampain)
    if (response.status < 400) {
      return response.data
    }
  } catch (err) {
    return null
  }
}
export const fetchPlagues = async () => {
  try {
    console.log('FETCH -PLAGUES')
    const response = await httpApi.get('/plagues')
    if (response.status < 400) {
      return response.data
    }
  } catch (err) {
    return null
  }
}
export const fetchVarieties = async plot => {
  try {
    console.log('FETCH - VARIETIES: ' + plot)
    const response = await httpApi.get('/varieties/' + plot)
    if (response.status < 400) {
      return response.data
    }
  } catch (err) {
    return null
  }
}
export const fetchProducts = async plague => {
  try {
    console.log('FETCH - PRODUCTS: ' + plague)
    const response = await httpApi.get('/pps/' + plague)
    if (response.status < 400) {
      return response.data
    }
  } catch (err) {
    return null
  }
}
//
export const sendNewTreatment = async pps => {
  try {
    console.log('CREATE TREATMENT: ' + JSON.stringify(pps))
    //return 'OK'
    const response = await httpApi.post('/sendtreatment', pps)
    if (response.status < 400) {
      return response.data
    }
  } catch (err) {
    return null
  }
}
export const endNewTreatment = async treatment => {
  try {
    console.log('END CREATE TREATMENT: ' + JSON.stringify(treatment))
    const response = await httpApi.post('/endtreatment', treatment)
    if (response.status < 400) {
      return response.data
    }
  } catch (err) {
    return null
  }
}
export const deleteTreatment = async treatment => {
  try {
    console.log('DELETE TREATMENT: ' + JSON.stringify(treatment))
    const response = await httpApi.post('/deletetreatment', treatment)
    if (response.status < 400) {
      return response.data
    }
  } catch (err) {
    return null
  }
}
