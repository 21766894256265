<template>
  <b-card>
    <b-card-body>
      <b-container fluid>
        <b-form ref="form" lab-width="150px">
          <b-row align-h="between">
            <b-col md="5" xs="12">
              <b-form-group
                label="Tipo de comunicación"
                label-size="lg"
                label-align="left"
                label-class="pl-0"
              >
                <b-form-select
                  v-model="noticeType"
                  :disabled="block"
                  :options="typeOptions"
                  class="mb-3"
                />
              </b-form-group>
              <b-form-group label="Título" label-size="lg" label-class="pl-0">
                <b-input v-model="name" minlength="5" />
              </b-form-group>

              <b-form-group
                label="Descripción"
                label-size="lg"
                label-class="pl-0"
              >
                <b-form-textarea
                  v-model="description"
                  minlength="5"
                  type="textarea"
                  autosize
                />
              </b-form-group>
              <b-form-group
                label="Tipo de documento adjunto"
                label-size="lg"
                label-align="left"
                label-class="pl-0"
              >
                <b-form-radio-group
                  v-model="documentMode"
                  :disabled="block"
                  :options="documentOptions"
                  class="pb-3"
                />
                <input
                  v-if="documentMode === 'unique'"
                  class="upload"
                  type="file"
                  :name="nameFile()"
                  file-count="$event.target.files.length"
                  @input="
                    setFileInfo({
                      fileName: $event.target.name,
                      fileList: $event.target.files,
                      uploaded: false,
                    })
                  "
                />
              </b-form-group>
            </b-col>
            <b-col md="5" xs="12">
              <b-form-group
                label="Notificación"
                label-size="lg"
                label-class="pl-0"
              >
                <b-form-checkbox-group
                  v-model="notifyBy"
                  :options="availableNotifyOptions"
                />
              </b-form-group>

              <b-form-group
                label="Opciones adicionales"
                label-size="lg"
                label-class="pl-0"
              >
                <b-form-checkbox
                  v-model="confirmationRequested"
                  :disabled="block"
                  :options="availableNotifyOptions"
                >
                  Requerir aceptación
                </b-form-checkbox>
                <b-form-group
                  label="Asistencia"
                  label-size="lg"
                  label-class="pl-0 pt-3"
                >
                  <b-form-group label="Fecha" label-class="pl-0">
                    <b-form-datepicker
                      v-model="date"
                      label-help=""
                      size="sm"
                      label-no-date-selected="Elige una fecha"
                      start-weekday="1"
                    />
                  </b-form-group>
                  <b-form-group label="Hora" label-class="pl-0">
                    <b-form-timepicker
                      v-model="hour"
                      label-no-date-selected="Elige una hora"
                      size="sm"
                      label-close-button="Cerrar"
                      placeholder="Hora"
                    />
                  </b-form-group>
                </b-form-group>
              </b-form-group>
              <b-form-group
                v-if="showReport"
                label="Acta de la convocatoria"
                label-size="lg"
                label-align="left"
                label-class="pl-0"
              >
                <input
                  class="upload"
                  type="file"
                  :name="nameFile()"
                  file-count="$event.target.files.length"
                  @input="
                    setReportInfo({
                      fileName: $event.target.name,
                      fileList: $event.target.files,
                      uploaded: false,
                    })
                  "
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'PlannerCallForm',
  props: {
    notice: {
      type: Object,
      default() {
        return { id: null }
      },
    },
    block: { type: Boolean, default: false },
  },
  data() {
    return {
      availableNotifyOptions: [
        { text: 'Enviar email', value: 'email' },
        { text: 'Enviar SMS', value: 'sms' },
      ],
      typeOptions: [
        { value: 'communication', text: 'Comunicación' },
        { value: 'call', text: 'Convocatoria' },
        { value: 'contract', text: 'Contratos' },
      ],
    }
  },
  computed: {
    isCall() {
      return this.noticeType === 'call'
    },
    showReport() {
      if (this.date === null) return false
      return this.isCall && new Date() > new Date(this.date)
    },
    documentOptions() {
      return [
        { text: 'Único', value: 'unique' },
        {
          text: 'Nominativo',
          value: 'pick',
          disabled: this.noticeType !== 'contract',
        },
      ]
    },
    name: {
      get() {
        return this.$store.state.notices.noticeToSave.name
      },
      set(value) {
        this.$store.commit('notices/setSaveName', value)
      },
    },
    description: {
      get() {
        return this.$store.state.notices.noticeToSave.description
      },
      set(value) {
        this.$store.commit('notices/setSaveDescription', value)
      },
    },
    date: {
      get() {
        return this.$store.state.notices.noticeToSave.date
      },
      set(value) {
        this.$store.commit('notices/setSaveDate', value)
      },
    },
    hour: {
      get() {
        return this.$store.state.notices.noticeToSave.hour
      },
      set(value) {
        this.$store.commit('notices/setSaveHour', value)
      },
    },
    notifyBy: {
      get() {
        return this.$store.state.notices.noticeToSave.notifyBy
      },
      set(value) {
        this.$store.commit('notices/setSaveNotifyBy', value)
      },
    },
    confirmationRequested: {
      get() {
        return this.$store.state.notices.noticeToSave.confirmationRequested
      },
      set(value) {
        this.$store.commit('notices/setSaveConfirmationRequested', value)
      },
    },
    noticeType: {
      get() {
        return this.$store.state.notices.noticeToSave.noticeType
      },
      set(value) {
        this.$store.commit('notices/setNoticeType', value)
      },
    },
    documentMode: {
      get() {
        return this.$store.state.notices.noticeToSave.documentMode
      },
      set(value) {
        this.$store.commit('notices/setdocumentMode', value)
      },
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    ...mapMutations('notices', [
      'setFileInfo',
      'setNoticeToSave',
      'setReportInfo',
    ]),
    async loadData() {},
    nameFile: function () {
      const dateTime = Date.now()
      return `notice_${Math.floor(dateTime / 1000)}`
    },
  },
}
</script>

<style scoped></style>
