<template>
  <b-card
    :bg-variant="
      treatmentCreated.variety.id === variety.id ? 'primary' : 'light'
    "
    :text-variant="
      treatmentCreated.variety.id === variety.id ? 'white' : 'black'
    "
    @click="selectedCard()"
  >
    <b-card-title>
      <h3>{{ variety.variedad }}</h3>
    </b-card-title>
  </b-card>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
// import { errorOnDataSave } from '@/services/messageService'
// import { deleteTreatment } from '@/services/treatmentsService'

export default {
  name: 'VarietyCard',
  props: {
    variety: {
      type: Object,
      default: () => ({}),
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    ...mapState('treatments', ['treatmentCreated']),
  },
  methods: {
    ...mapMutations('treatments', ['setVariety']),
    selectedCard() {
      this.setVariety(this.variety)
      console.log('SELECTED VARIETY ' + this.variety.variedad)
      // this.close()
    },
    // close() {
    //   this.$router.push({
    //     path: '/crear',
    //   })
    // },
  },
}
</script>
<style>
.card-body {
  padding: 0.8rem;
}
h3 {
  font-size: 1em;
}
</style>
