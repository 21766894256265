export const routerModule = {
  namespaced: true,
  state: {
    redirectionAfterLogin: null,
  },
  mutations: {
    setRedirectionAfterLogin(state, redirection) {
      state.redirectionAfterLogin = redirection
    },
  },
  actions: {
    setRedirectionAfterLogin: ({ commit }, redirection) => {
      commit('setRedirectionAfterLogin', redirection)
    },
  },
  getters: {
    redirectionAfterLogin(state) {
      return state.redirectionAfterLogin
    },
  },
}
