<template>
  <b-card
    :bg-variant="treatmentCreated.plague.id === plague.id ? 'primary' : 'light'"
    :text-variant="treatmentCreated.plague.id === plague.id ? 'white' : 'black'"
    @click="selectedCard()"
  >
    <b-card-title class="mt-2">
      <h3>{{ plague.descripcion }}</h3>
    </b-card-title>
  </b-card>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
// import { errorOnDataSave } from '@/services/messageService'
// import { deleteTreatment } from '@/services/treatmentsService'

export default {
  name: 'PlagueCard',
  props: {
    plague: {
      type: Object,
      default: () => ({}),
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    ...mapState('treatments', ['treatmentCreated']),
  },
  methods: {
    ...mapMutations('treatments', ['setPlague']),
    selectedCard() {
      this.setPlague(this.plague)
      console.log('SELECTED VARIETY ' + this.plague.variedad)
      // this.close()
    },
    // close() {
    //   this.$router.push({
    //     path: '/crear',
    //   })
    // },
  },
}
</script>
<style>
.card-body {
  padding: 0.8rem;
}
h3 {
  font-size: 1em;
}
</style>
