<template>
  <b-container fluid>
    <b-overlay :show="isLoading">
      <b-row align-h="end">
        <b-col cols="12">
          <b-button block variant="primary" to="/crear">
            Añadir Tratamiento
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="10" xs="12">
          <control-list-bar
            @searchChange="newSearch => (searchedText = newSearch)"
          />
        </b-col>
        <b-col md="2" xs="12">
          <div class="mt-4 mb-2">
            CAMPAÑA: <strong>{{ selectedCampain }}</strong>
          </div>
          <!-- <div style="padding-top: 6px">
            <b-form-select
              v-model="selectedCampain"
              :options="listCampain"
              size="sm"
              class="mt-3"
              @input="selectSelectedCampain"
            >
            </b-form-select> -->
          <!-- </div> -->
        </b-col>
      </b-row>
      <template v-if="treatments.length">
        <b-row v-for="treatment in filteredTreatments" :key="treatment.id">
          <b-col align-self="center">
            <treatmentclosed-card :treatment="treatment" />
          </b-col>
        </b-row>
      </template>
      <b-row v-else>
        <b-col cols="12" align-self="center">
          <p class="text-center font-weight-bold bg-light text-uppercase">
            No hay tratamientos disponibles
          </p>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>
<script>
import names from '@/config/names'
import { mapState, mapActions, mapMutations } from 'vuex'
import ControlListBar from '@/components/search/ControlListBar'
import TreatmentclosedCard from '@/components/cards/TreatmentClosedCard'

export default {
  name: 'TreatmentList',
  components: {
    TreatmentclosedCard,
    ControlListBar,
  },
  data() {
    // let selcampain = this.campain
    return {
      searchedText: '',
      names,
      isLoading: true,
      selectedCampain: '',
      listCampain: [],
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('treatments', [
      'treatments',
      'treatmentCreated',
      'campain',
      'campains',
      'selcampain',
    ]),
    filteredTreatments() {
      const filteredTreatments = this.treatments.filter(treatment => {
        for (const property in treatment) {
          if (
            typeof treatment[property] === 'string' &&
            treatment[property]
              .toLowerCase()
              .includes(this.searchedText.toLowerCase())
          ) {
            return true
          }
        }
        return false
      })
      // .sort(
      //   (a, b) =>
      //     a.paleta - b.paleta ||
      //     a.nlineacontrato - b.nlineacontrato ||
      //     a.nlinea - b.nlinea
      // )
      return filteredTreatments
    },
  },
  watch: {
    user: function () {
      if (this.user) {
        if (this.user.owner) this.setOwnerRole(this.user.owner.ownerNumber)
        else this.setRunnerRole(this.user.runner.runnerNumber)
        this.fetchTreatments()
      }
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    ...mapActions('treatments', [
      'fetchTreatments',
      'fetchCampains',
      'fetchActiveCampain',
    ]),
    ...mapMutations('treatments', [
      'setOwnerRole',
      'setRunnerRole',
      'resetTreatmentCreate',
      'setSelectedCampain',
    ]),
    loadData: async function () {
      this.resetTreatmentCreate()
      //
      await this.fetchActiveCampain()
      this.selectedCampain = this.campain
      await this.fetchCampains()
      const liscampain = []
      this.campains.forEach(obj => {
        // objson.selected = obj.Activa
        liscampain.push({
          value: obj.Campanya.replace('/', '-'),
          text: obj.Campanya,
        })
      })
      this.listCampain = liscampain
      //
      if (this.user) {
        if (this.user.runner) this.setRunnerRole(this.user.runner.runnerNumber)
        else this.setOwnerRole(this.user.owner.ownerNumber)
        await this.fetchTreatments()
      }

      this.isLoading = false
    },
    selectSelectedCampain: async function () {
      console.log({ SELECTED: this.selectedCampain })
      this.isLoading = true
      this.setSelectedCampain(this.selectedCampain)
      // console.log({ CAMPANYA: this.campain })
      // console.log({ SELECTEDCAMPANYA: this.selectedCampain })
      await this.fetchTreatments()
      this.isLoading = false
    },
  },
}
</script>

<style scoped></style>
