<template>
  <b-container fluid>
    <b-overlay :show="isLoading">
      <template v-if="treatmentsCreatedList.length > 0">
        <b-button block variant="primary" @click="onSaveTreatmentComplete">
          FINALIZAR
        </b-button>
        <br />
      </template>
      <b-row align-v="end" class="mt-3 mb-3">
        <b-col cols="8">
          <h3>
            {{
              treatmentCreated.variety.variedad
                ? treatmentCreated.plot.paleta +
                  ' : ' +
                  treatmentCreated.variety.variedad
                : treatmentCreated.plot.paleta
                ? treatmentCreated.plot.paleta
                : 'Seleccione '
            }}
          </h3>
          <h4>
            {{
              treatmentCreated.plot.paleta
                ? treatmentCreated.plot.termino +
                  ' ( ' +
                  treatmentCreated.plot.partida +
                  ' )'
                : ' .'
            }}
          </h4>
        </b-col>
        <b-col cols="4">
          <b-button-group>
            <b-button
              size="lg"
              :variant="
                tabIndex == 0
                  ? 'outline-secondary'
                  : isPPS
                  ? 'warning'
                  : 'primary'
              "
              @click="clickBefore()"
            >
              <i
                :class="
                  isPPS ? 'fa fa-window-close' : 'fas fa-arrow-circle-left'
                "
              ></i>
              <!-- <i class="fas fa-arrow-circle-left"></i> -->
            </b-button>
            <b-button
              size="lg"
              :variant="tabIndex == 2 ? 'secondary' : 'primary'"
              @click="clickAfter()"
            >
              <i class="fas fa-arrow-circle-right"></i>
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col>
          <b-button-group>
            <b-button
              squared
              :variant="tabIndex == 0 ? 'secondary' : 'outline-primary'"
              @click="clickTab(0)"
              >PALETA</b-button
            >
            <b-button
              squared
              :variant="tabIndex == 1 ? 'secondary' : 'outline-primary'"
              @click="clickTab(1)"
              >VARIEDAD</b-button
            >
            <b-button
              squared
              :variant="tabIndex == 2 ? 'secondary' : 'outline-primary'"
              :disabled="treatmentCreated.variety != '' ? false : true"
              @click="clickTab(2)"
              >APLICACIONES</b-button
            >
          </b-button-group>
        </b-col>
      </b-row>

      <template v-if="tabIndex == 0">
        <PlotList />
      </template>
      <template v-if="tabIndex == 1">
        <VarietyList />
      </template>
      <template v-if="tabIndex == 2">
        <TreatmentPPSList />
      </template>
      <!-- <div>Tab: {{ tabIndex }}</div> -->
      <br />
    </b-overlay>
  </b-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
// import { errorOnDataSave, errorOnDataLoad } from '@/services/messageService'
// import { formatDateForDatepicker, getCurrentHour } from '@/services/time'
// import {
//   fetchTreatmentById,
//   deleteTreatment,
// } from '@/services/treatmentsService'
import PlotList from '@/pages/treatments/TreatmentPlotList.vue'
import VarietyList from '@/pages/treatments/TreatmentVarietyList.vue'
// import TreatmentCreatePPS from '@/pages/treatments/TreatmentCreatePPS.vue'
import TreatmentPPSList from '@/pages/treatments/TreatmentPPSList.vue'

export default {
  name: 'TreatmentCreateCore',
  components: {
    PlotList,
    VarietyList,
    // TreatmentCreatePPS,
    TreatmentPPSList,
  },
  props: {
    treatment: {
      type: Object,
      default() {
        return { id: null }
      },
    },
  },
  data() {
    return {
      tabIndex: '',
      isLoading: true,
      isClosing: false,
      isPPS: false,
    }
  },
  computed: {
    ...mapState('treatments', [
      'ownercode',
      'runnercode',
      'treatmentCreated',
      'treatmentsCreatedList',
    ]),
  },
  mounted() {
    console.log('CREANDO: ' + JSON.stringify(this.treatmentCreated))
    this.loadData(0)
  },
  methods: {
    ...mapActions('treatments', [
      'fetchPlots',
      'fetchVarieties',
      'fetchPlagues',
      'fetchProducts',
      'savePPS',
      'saveTreatment',
    ]),
    // ...mapMutations('treatments', [ ]),
    clickTab(tab) {
      console.log('CLICK' + tab)
      this.loadData(tab)
      this.isLoading = false
    },
    clickBefore() {
      if (this.tabIndex == 0) {
        this.loadData(0)
      } else {
        if (this.isPPS) this.close()
        this.tabIndex -= 1
        this.loadData(this.tabIndex)
      }
      this.isLoading = false
    },
    clickAfter() {
      if (this.tabIndex == 2) {
        this.loadData(2)
      } else {
        this.tabIndex += 1
        this.loadData(this.tabIndex)
      }
      this.isLoading = false
    },
    loadData: async function (tab) {
      if (this.ownercode == '' && this.runnercode == '') {
        this.$router.push({
          path: '/tratamientos',
        })
      }
      if (!this.isPPS) {
        if (this.$route.query.tab > 0) {
          console.log('Entro con parametros')
          tab = this.$route.query.tab

          this.isPPS = true
        }
      }
      console.log('En loadData CORE' + tab)
      this.tabIndex = tab
      // if (this.treatmentCreated.plot == '') {
      //   console.log('He perdido los datos CANCELO ')
      //   this.$router.push({
      //     path: '/tratamientos',
      //   })
      // }
      this.isLoading = true
      switch (tab) {
        default:
          await this.fetchPlots()
          break
        case 1:
          await this.fetchVarieties()
          break
        case 2:
          if (this.treatmentsCreatedList.length == 0) {
            console.log('Lo mando a crear PPS si no hay ')
            this.$router.push({
              path: '/pps',
            })
          }
          break
      }
      // const fetchData = {
      //   0: this.fetchPlots,
      //   1: this.fetchVarieties,
      //   2: this.fetchPlagues,
      //   3: this.fetchProducts,
      // }
      // await fetchData[tab]()
      this.isLoading = false
    },
    async onSaveTreatmentComplete() {
      console.log('onSaveTreatmentComplete')
      if (this.treatmentsCreatedList.length < 0) {
        // return
        console.log('Lo mando a crear PPS si no hay ')
        this.treatmentsCreatedList.forEach(element => {
          console.log(JSON.stringify(element))
        })
        this.$router.push({
          path: '/tratamientos',
        })
      }
      this.isLoading = true
      //   await publish(this.addedNotice.id)
      console.log('ACTUALIZAR ****************** ')
      await this.savePPS()
      await this.saveTreatment()
      this.isClosing = true
      this.isLoading = false
      this.close()
    },
    async checkLoad(value) {
      console.log('checkLoad ' + value)
    },

    close() {
      console.log('CLOSE')
      this.$router.push({
        path: '/tratamientos',
      })
    },
  },
}
</script>

<style scoped>
h3 {
  font-size: 1em;
}
h4 {
  font-size: 1em;
  font-weight: 400;
}
</style>
