<template>
  <b-card
    :bg-variant="
      treatmentCreated.product.id === product.id ? 'primary' : 'light'
    "
    :text-variant="
      treatmentCreated.product.id === product.id ? 'white' : 'black'
    "
    @click="selectedCard()"
  >
    <b-card-title>
      <h3>{{ product.descripcion }}</h3>
    </b-card-title>
    <b-card-text>
      <p style="font-size: 80%; color: #666">
        {{ product.comercial }}
        <br />{{ product.registro }}
      </p>

      <p>{{ product.plaga }}</p>
    </b-card-text>
  </b-card>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
// import { errorOnDataSave } from '@/services/messageService'
// import { deleteTreatment } from '@/services/treatmentsService'

export default {
  name: 'ProductCard',
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    ...mapState('treatments', ['treatmentCreated']),
  },
  methods: {
    ...mapMutations('treatments', ['setProduct']),
    selectedCard() {
      this.setProduct(this.product)
      console.log('SELECTED PRODUCT ' + this.product.producto)
      // this.close()
    },
    // close() {
    //   this.$router.push({
    //     path: '/crear',
    //   })
    // },
  },
}
</script>
<style>
.card-body {
  padding: 0.8rem;
}
h3 {
  font-size: 1em;
}
</style>
