<template>
  <b-card no-body align-self="center" class="mb-3">
    <b-card-title class="mt-2">
      <b-container fluid>
        <b-row align-h="between">
          <b-col cols="12">
            <h3>{{ document.name }}</h3>
          </b-col>
        </b-row>
      </b-container>
    </b-card-title>
    <b-card-sub-title>
      <b-container fluid>
        <b-row align-h="between">
          <b-col md="3" xs="12">
            <span class="mr-3">{{ document.date | toDateTime }}</span>
          </b-col>
          <b-col md="9" xs="12">
            <span v-if="isAdmin">
              Subido por <b>{{ document.username }}</b>
            </span>
          </b-col>
          <b-col cols="6" />
        </b-row>
      </b-container>
    </b-card-sub-title>
    <b-card-body>
      <b-row>
        <b-col md="8" xs="12">
          <b-card-text>
            {{ document.description }}
          </b-card-text>
        </b-col>
        <b-col md="4" xs="12">
          <b-button
            variant="primary"
            size="lg"
            block
            class="mr-3"
            @click="openDocument()"
          >
            Ver documento
          </b-button>
          <b-button variant="danger" size="lg" block @click="deleteFile()">
            Borrar documento
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>
<script>
import { openFile } from '@/services/filesService.js'
import { mapActions } from 'vuex'
import {
  successOnDataDelete,
  errorOnDataDelete,
  errorOnFileLoad,
} from '@/services/messageService'
export default {
  name: 'DocCard',
  props: {
    document: {
      type: Object,
      default: () => ({}),
    },
    isAdmin: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    ...mapActions('documentation', ['deleteDocument']),
    async deleteFile() {
      try {
        // @TODO move to message service
        const sureAboutDelete = await this.$bvModal.msgBoxConfirm(
          `Está seguro de querer eliminar el documento ${this.document.name}?`,
          {
            okVariant: 'danger',
            okTitle: 'Sí',
            cancelTitle: 'No',
          }
        )

        if (!sureAboutDelete) return
        if (await this.deleteDocument(this.document.id)) {
          successOnDataDelete({ context: this })
        } else {
          errorOnDataDelete({
            context: this,
            error: 'Petition failed',
          })
        }
      } catch (error) {
        errorOnDataDelete({
          context: this,
          error: 'Caught in component',
        })
      }
    },
    async openDocument() {
      try {
        const fileProperties = {
          entity: 'document',
          entityID: this.document.id,
          extension: this.document.extension,
          name: this.document.name,
        }
        await openFile(fileProperties)
      } catch (error) {
        errorOnFileLoad({
          context: this,
          error,
        })
      }
    },
  },
}
</script>
<style></style>
