import { render, staticRenderFns } from "./RecipientContractList.vue?vue&type=template&id=477b30aa&scoped=true&"
import script from "./RecipientContractList.vue?vue&type=script&lang=js&"
export * from "./RecipientContractList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "477b30aa",
  null
  
)

export default component.exports