<template>
  <b-container fluid>
    <b-overlay :show="isLoading">
      <b-card v-if="notice" no-body>
        <b-card-title class="mt-2">
          <b-container fluid>
            <b-row align-h="between">
              <b-col md="12" sm="12">
                <h3>{{ notice.name }}</h3>
              </b-col>
            </b-row>
          </b-container>
        </b-card-title>
        <b-card-sub-title>
          <b-container fluid>
            {{ notice.date | toDateTime }}
          </b-container>
        </b-card-sub-title>
        <b-card-body>
          <b-row>
            <b-col md="8" sm="12">
              <p>{{ notice.description }}</p>
              <b-row>
                <h5>
                  <b-badge class="ml-2">
                    {{
                      notice.confirmationRequested
                        ? 'Confirmación requerida'
                        : 'Confirmación no requerida'
                    }}
                  </b-badge>
                </h5>
                <h5>
                  <b-badge class="ml-2">
                    {{ notice.published ? 'Publicada' : 'No publicada' }}
                  </b-badge>
                </h5>
              </b-row>
              <p v-if="notice.notifyBy && notice.notifyBy.length">
                <b class="mr-2">Notificado mediante:</b>
                <b-badge
                  v-for="method in notice.notifyBy"
                  :key="method"
                  class="mr-2"
                >
                  {{ method.toUpperCase() }}
                </b-badge>
              </p>
            </b-col>
            <b-col md="4" sm="12">
              <b-button
                :variant="notice.hasCommunicationFile ? 'primary' : ''"
                :disabled="!notice.hasCommunicationFile"
                block
                size="lg"
                @click="openPdf('notice')"
              >
                {{
                  notice.documentMode === 'unique'
                    ? notice.hasCommunicationFile
                      ? `Ver ${noticeType}`
                      : 'Sin documento'
                    : `Documento nominativo`
                }}
              </b-button>
              <b-button
                v-if="notice.noticeType === 'call'"
                block
                :disabled="!notice.hasReportFile"
                :variant="notice.hasReportFile ? 'primary' : ''"
                size="lg"
                @click="openPdf('report')"
              >
                {{ notice.hasReportFile ? `Ver acta` : 'Sin acta' }}
              </b-button>
            </b-col>
          </b-row>
          <hr />
          <b-row class="mb-3">
            <h4 class="ml-3 mb-3">Destinatarios</h4>
            <sender-recipient-table
              :recipients="notice.recipients"
              :confirmation-requested="notice.confirmationRequested"
              :contracts="notice.noticeType === 'contract'"
            />
          </b-row>
        </b-card-body>
        <template slot="footer">
          <div class="float-right">
            <b-button
              class="mr-3"
              variant="primary"
              @click="showUpdateScreen()"
            >
              Editar
            </b-button>
            <b-button variant="warning" @click="close()"> Volver </b-button>
          </div>
        </template>
      </b-card>
      <div v-else>No exite una comunicación con este ID</div>
    </b-overlay>
  </b-container>
</template>

<script>
import { openFile } from '@/services/filesService.js'
import { errorOnFileLoad, errorOnDataLoad } from '@/services/messageService'
import { fetchNoticeById, fetchRecipients } from '@/services/noticesService'
import SenderRecipientTable from '@/pages/notices/sender/SenderRecipientTable'
export default {
  name: 'NoticeDetails',
  components: {
    SenderRecipientTable,
  },
  props: {
    noticeFromList: {
      type: Object,
      default() {
        return { id: null }
      },
    },
  },
  data() {
    return {
      isLoading: false,
      notice: {},
      pdfUrl: '',
      types: {
        communication: 'comunicación',
        call: 'convocatoria',
        contract: 'contrato',
      },
    }
  },
  computed: {
    noticeType() {
      return `${this.types[this.notice.noticeType]}` || ''
    },
    noticeTypePron() {
      return this.notice.noticeType === 'contract'
        ? `Este ${this.noticeType}`
        : `Esta ${this.noticeType}`
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    async loadData() {
      try {
        this.isLoading = true
        const noticeId = parseInt(this.$route.params.id)
        if (this.noticeFromList.id) {
          this.notice = {
            ...this.noticeFromList,
            recipients: (await fetchRecipients(noticeId))?.recpients,
          }
        } else {
          this.notice = {
            ...(await fetchNoticeById(noticeId)),
            recipients: (await fetchRecipients(noticeId))?.recipients,
          }
        }
        this.isLoading = false
      } catch (error) {
        errorOnDataLoad({ context: this, error })
      }
    },
    async openPdf(entity) {
      try {
        const fileProperties = {
          entity,
          entityID: this.notice.id,
          name: this.withholding.name,
        }
        await openFile(fileProperties)
      } catch (error) {
        errorOnFileLoad({ context: this, error })
      }
    },
    showUpdateScreen() {
      this.$router.push({
        path: `/admin/comunicaciones/${this.notice.id}/editar`,
      })
    },
    close() {
      this.$router.push({
        path: '/admin/comunicaciones',
      })
    },
  },
}
</script>

<style scoped>
@media (max-height: 950px) {
  .detail-table {
    max-height: 200px;
  }
}

@media (min-height: 950px) {
  .detail-table {
    max-height: 500px;
  }
}

p {
  word-break: normal;
  font-size: 18px;
}
</style>
