<template>
  <b-container fluid>
    <control-list-bar @searchChange="newSearch => (searchedText = newSearch)" />
    <template v-if="notices.length">
      <b-row v-for="notice in filteredNotices" :key="notice.id">
        <b-col align-self="center">
          <notice-card :notice="notice" />
        </b-col>
      </b-row>
    </template>
    <b-row v-else>
      <b-col cols="12" align-self="center">
        <p class="text-center font-weight-bold bg-light text-uppercase">
          No hay comunicaciones disponibles aún
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import NoticeCard from '@/components/cards/NoticeCard'
import names from '@/config/names'
import tagsColors from '@/pages/notices/recipient/tagColors.json'
import { mapActions, mapState } from 'vuex'
import ControlListBar from '@/components/search/ControlListBar'
import { errorOnDataLoad } from '@/services/messageService'

export default {
  name: 'RecipientNoticeList',
  components: {
    NoticeCard,
    ControlListBar,
  },
  data() {
    return {
      sortBy: 'date',
      searchedText: '',
      isLoading: true,
      names,
    }
  },
  computed: {
    ...mapState('notices', { notices: 'communicationRecipients' }),
    filteredNotices() {
      return this.notices
        .filter(notice => {
          for (const property of Object.values(notice)) {
            if (
              typeof property === 'string' &&
              property.toLowerCase().includes(this.searchedText.toLowerCase())
            ) {
              return true
            }
          }
        })
        .sort((a, b) => new Date(b.date) - new Date(a.date))
    },
  },
  watch: {
    filteredNotices: {
      handler: function () {},
      deep: true,
    },
    notices: {
      handler: function () {},
      deep: true,
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    ...mapActions('notices', ['fetchNoticeRecipients']),
    retrieveTableRowClassName({ row }) {
      if (row.status === 'pending') {
        return 'warning-row'
      }
      return ''
    },
    selectStatusColor(status) {
      return tagsColors[status]
    },
    async loadData() {
      try {
        await this.fetchNoticeRecipients('communication')
        this.isLoading = false
      } catch (error) {
        errorOnDataLoad({ context: this, error })
      }
    },
  },
}
</script>

<style scoped></style>
