<template>
  <b-form
    class="login-form"
    label-position="top"
    label-width="100px"
    :model="loginData"
  >
    <b-form-group label="Usuario">
      <b-form-input
        v-model="loginData.username"
        placeholder="Usuario"
        sufix-icon="el-icon-user"
      />
    </b-form-group>
    <b-form-group label="Contraseña">
      <b-form-input
        v-model="loginData.password"
        placeholder="Contraseña"
        type="password"
        @keyup.enter.native="login()"
      />
    </b-form-group>

    <b-form-group>
      <b-button variant="primary" class="mr-3 mb-3" @click="login()">
        Iniciar sesión
      </b-button>
      <b-button size="sm" class="mb-3" @click="setMode('recovery')">
        Recuperar contraseña
      </b-button>
    </b-form-group>
  </b-form>
</template>

<script>
import { login } from '@/services/authService'
import { mapState, mapMutations } from 'vuex'
import {
  errorOnLoginInvalid,
  errorOnLogin,
  errorOnLoginEmptyFields,
} from '@/services/messageService'

export default {
  name: 'LoginForm',
  data() {
    return {
      loginData: {
        username: '',
        password: '',
      },
      isTouched: false,
      error: false,
      shouldChangeDefaultPassword: false,
    }
  },
  computed: {
    ...mapState('router', ['redirectionAfterLogin']),
    isInputValid() {
      return this.loginData.username !== '' && this.loginData.password !== ''
    },
    hasErrors() {
      return this.isTouched && !this.isInputValid
    },
  },
  methods: {
    ...mapMutations('auth', ['setMode', 'setOptions']),
    ...mapMutations('router', ['setRedirectionAfterLogin']),
    async login() {
      if (this.isInputValid) {
        try {
          const loginSuccesful = await login(this.loginData)
          if (
            loginSuccesful &&
            btoa(this.loginData.password) === 'c2F0OTQyMg==' &&
            this.shouldChangeDefaultPassword
          ) {
            this.setOptions({ local: true })
            this.setMode('password')
          } else if (loginSuccesful) {
            const { redirectionAfterLogin } = this
            const redirecTo = redirectionAfterLogin || '/'
            this.setRedirectionAfterLogin('/')
            this.$router.replace({ path: redirecTo }).catch(error => {
              errorOnLogin(error)
            })
          } else {
            errorOnLoginInvalid({ context: this })
          }
        } catch (error) {
          errorOnLogin({ context: this, error })
        }
      } else {
        errorOnLoginEmptyFields({ context: this })
      }
    },
  },
}
</script>

<style scoped>
.login-form {
  width: 80%;
  margin-left: 10%;
}

.login-button {
  margin-right: 10px;
}

.recovery-button {
  margin-left: 0px;
}
/*
.phone-input:focus-within {
  position: absolute;
  bottom: 80vh;
}*/
</style>
