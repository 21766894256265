import { uploadNotice, uploadReportFile } from '@/services/filesService'
import {
  acceptNotice,
  addNotice,
  addRecipients,
  addRecipientsByQuery,
  deleteNotice,
  deleteRecipients,
  deleteRecipientsByQuery,
  fetchNoticeById,
  fetchNoticeRecipients,
  fetchNotices,
  fetchRecipients,
  rejectNotice,
  seenNotice,
  updateNotice,
} from '@/services/noticesService'
import { formatDateForDatepicker, getCurrentHour } from '@/services/time'

export const noticesModule = {
  namespaced: true,
  state: {
    communicationRecipients: [],
    callRecipients: [],
    contractRecipients: [],
    notices: [],
    addedNotice: {},
    noticeToSave: {
      name: '',
      description: '',
      notifyBy: [],
      date: formatDateForDatepicker(),
      hour: getCurrentHour(),
      confirmationRequested: false,
      noticeType: 'communication',
      documentMode: 'unique',
    },
    fileInfo: { fileName: '', fileList: [], uploaded: false },
    reportInfo: { fileName: '', fileList: [], uploaded: false },
  },
  mutations: {
    setNotices(state, notices) {
      state.notices = notices
    },
    setFileInfo(state, fileInfo) {
      state.fileInfo = fileInfo
    },
    setReportInfo(state, fileInfo) {
      state.reportInfo = fileInfo
    },
    setNotice(state, notice) {
      const noticeIndex = state.notices.findIndex(el => notice.id === el.id)
      if (noticeIndex < 0) {
        state.notices.push(notice)
      } else {
        state.notices[noticeIndex] = notice
      }
    },
    setNoticeToSave(state, notice) {
      state.noticeToSave = notice
    },
    setSaveName(state, name) {
      state.noticeToSave.name = name
    },
    setSaveDescription(state, description) {
      state.noticeToSave.description = description
    },
    setSaveNotifyBy(state, notifyBy) {
      state.noticeToSave.notifyBy = notifyBy
    },
    setSaveDate(state, date) {
      state.noticeToSave.date = formatDateForDatepicker(date)
    },
    setSaveHour(state, hour) {
      state.noticeToSave.hour = hour
    },
    setdocumentMode(state, documentMode) {
      state.noticeToSave.documentMode = documentMode
    },
    setSaveConfirmationRequested(state, confirmationRequested) {
      state.noticeToSave.confirmationRequested = confirmationRequested
    },
    setNoticeType(state, noticeType) {
      state.noticeToSave.noticeType = noticeType
    },
    addNotice(state, addNotice) {
      state.notices.push(addNotice)
    },
    setAddedNotice(state, notice) {
      state.addedNotice = notice
    },
    updateNotice(state, notice) {
      const noticeToUpdateIndex = state.notices.findIndex(
        el => notice.id === el.id
      )
      state.notices[noticeToUpdateIndex] = notice
    },
    deleteNotice(state, notice) {
      state.notices = state.notices.filter(el => notice.id !== el.id)
    },
    setCommunicationRecipients(state, communicationRecipients) {
      state.communicationRecipients = communicationRecipients
    },
    setCallRecipients(state, callRecipients) {
      state.callRecipients = callRecipients
    },
    setContractRecipients(state, contractRecipients) {
      state.contractRecipients = contractRecipients
    },
    setNoticeRecipient(state, notice) {
      const noticeIndex = state.noticeRecipients.findIndex(
        el => notice.id === el.id
      )
      if (noticeIndex < 0) {
        state.noticeRecipients.push(notice)
      } else {
        state.noticeRecipients[noticeIndex] = notice
      }
    },
  },
  actions: {
    fetchNotices: async ({ commit }) => {
      const notices = await fetchNotices()
      if (notices) {
        commit('setNotices', notices)
      }
    },
    fetchNoticeById: async ({ commit }, noticeId) => {
      const notice = await fetchNoticeById(noticeId)
      if (notice) {
        commit('setNotice', notice)
      }
    },
    addNotice: async ({ commit, state }) => {
      const noticeToSave = { ...state.noticeToSave }
      noticeToSave.date = new Date(
        `${state.noticeToSave.date}T${state.noticeToSave.hour}`
      )
      const addedNotice = await addNotice(noticeToSave)
      if (addedNotice) {
        commit('addNotice', addedNotice)
        commit('setAddedNotice', addedNotice)
      }
    },
    updateNotice: async ({ commit, state }) => {
      const noticeToSave = { ...state.noticeToSave }
      noticeToSave.date = new Date(
        `${state.noticeToSave.date}T${state.noticeToSave.hour}`
      )
      const updatedNotice = await updateNotice(
        state.addedNotice.id,
        noticeToSave
      )
      if (updatedNotice) {
        commit('updateNotice', updatedNotice)
        commit('setAddedNotice', updatedNotice)
      }
    },
    deleteNotice: async ({ commit }, noticeId) => {
      const deletedNotice = await deleteNotice(noticeId)
      if (deletedNotice) {
        commit('deleteNotice', deletedNotice)
      }
    },
    fetchNoticeRecipients: async ({ commit }, type) => {
      const recipients = await fetchNoticeRecipients(type)
      if (recipients && recipients.length > 0) {
        if (type === 'communication') {
          commit('setCommunicationRecipients', recipients)
        } else if (type === 'call') {
          commit('setCallRecipients', recipients)
        } else if (type === 'contract') {
          commit('setContractRecipients', recipients)
        }
      }
    },
    seenNotice: async (_, noticeId) => {
      const notice = await seenNotice(noticeId)
      if (notice && 'id' in notice) {
        // commit('setNoticeRecipient', notice)
      }
    },
    acceptNotice: async (_, noticeId) => {
      await acceptNotice(noticeId)
      /* if (notice) {
        commit('setNoticeRecipient', notice)
      } */
    },
    rejectNotice: async (_, noticeId) => {
      await rejectNotice(noticeId)
      /* if (notice) {
        commit('setNoticeRecipient', notice)
      } */
    },
    addRecipients: async ({ state }, ops) => {
      if (!state.addedNotice.id) throw new Error('No notice to modify')
      await addRecipients(state.addedNotice.id, ops.recipients, ops.contractOps)
    },
    deleteRecipients: async ({ state }, recipients) => {
      if (!state.addedNotice.id) throw new Error('No notice to modify')
      await deleteRecipients(state.addedNotice.id, recipients)
    },
    addQuery: async ({ state }, query) => {
      if (!state.addedNotice.id) throw new Error('No notice to modify')
      await addRecipientsByQuery(state.addedNotice.id, query)
    },
    deleteQuery: async ({ state }, query) => {
      if (!state.addedNotice.id) throw new Error('No notice to modify')
      await deleteRecipientsByQuery(state.addedNotice.id, query)
    },
    fetchRecipients: async ({ commit }, noticeId) => {
      const recipientsResponse = await fetchRecipients(noticeId)
      if (recipientsResponse) {
        commit('setRecipients', { noticeId, recipientsResponse })
      }
    },
    uploadFile: async ({ commit, state }, noticeId) => {
      if (!state.fileInfo.uploaded && state.fileInfo.fileName) {
        const notice = await uploadNotice(
          state.fileInfo.fieldName,
          state.fileInfo.fileList,
          noticeId,
          !!state.addedNotice.file
        )
        const fileInfo = { ...state.fileInfo, uploaded: !!notice }
        commit('setFileInfo', fileInfo)
      }
    },
    uploadReport: async ({ commit, state }, noticeId) => {
      if (!state.reportInfo.uploaded && state.reportInfo.fileName) {
        const notice = await uploadReportFile(
          state.reportInfo.fieldName,
          state.reportInfo.fileList,
          noticeId,
          !!state.addedNotice.report
        )
        const fileInfo = { ...state.reportInfo, uploaded: !!notice }
        commit('setFileInfo', fileInfo)
      }
    },
    fetchByIdWithRecipients: async ({ dispatch }, noticeId) => {
      await dispatch('fetchNoticeById', noticeId)
      await dispatch('fetchRecipients', noticeId)
    },
  },
  getters: {
    findNoticeById(state) {
      return id => {
        const notice = state.notices.find(el => el.id === id)
        return notice
      }
    },
    findRecipientsOfNotice(state) {
      return id => {
        const notice = state.notices.find(el => el.id === id)
        if (notice && notice.id) {
          return notice.recipients
        }
      }
    },
    findNoticeRecipientById(state) {
      return id => {
        return state.noticeRecipients.find(el => el.id === id)
      }
    },
  },
}
