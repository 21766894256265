<template>
  <b-card no-body class="mb-3">
    <b-card-title class="mt-2">
      <b-container fluid>
        <b-row align-h="between">
          <b-col md="8" xs="12">
            <h3>{{ treatment.paleta }} {{ treatment.campanya }}</h3>
            <p style="font-size: 70%; color: #666">
              Introducido el {{ treatment.fechacreacion | toDate }}
            </p>
          </b-col>
          <b-col md="4" xs="12">
            <h4 style="color: #666">{{ treatment.variedad }}</h4>
          </b-col>
        </b-row>
      </b-container>
    </b-card-title>
    <b-card-body>
      <!-- <b-row>
        <b-col md="8" xs="12">
          <p style="color: #666">{{ treatment.producto }}</p>
        </b-col>
        <b-col md="4" xs="12">
          <p>{{ treatment.plaga }}</p>
        </b-col>
      </b-row> -->
      <b-row v-for="pps in treatment.pps" :key="pps.nlinea">
        <b-col class="mb-3">
          <hr />
          <h3>{{ pps.producto }}</h3>
          <p style="font-size: 70%; color: #666">{{ pps.plaga }}</p>
          <h3 style="color: #666; padding-top: 0">{{ pps.fechaaplicacion }}</h3>
        </b-col>
      </b-row>
    </b-card-body>

    <b-card-footer>
      <b-row>
        <b-col cols="12">
          <b-button size="sm" variant="danger" @click="DeleteTreatment()"
            >ELIMINAR</b-button
          >
        </b-col>
      </b-row>
    </b-card-footer>
  </b-card>
</template>
<script>
import { mapActions } from 'vuex'
import { errorOnDataSave, confirmMessage } from '@/services/messageService'
// import { deleteTreatment } from '@/services/treatmentsService'

export default {
  name: 'TreatmentclosedCard',
  props: {
    treatment: {
      type: Object,
      default: () => ({}),
    },
  },
  // data() {
  //   return {}
  // },
  methods: {
    ...mapActions('treatments', ['deleteTreatment']),
    async DeleteTreatment() {
      console.log('ELIMINAR ' + this.treatment.nlineacontrato)

      const message =
        '¿ Desea eliminar los tratamientos de fecha ' +
        this.treatment.pps[0].fechaaplicacion +
        ' de la paleta ' +
        this.treatment.paleta +
        ' ?'

      let isConfirmed = await confirmMessage({
        context: this,
        message,
        title: '¡Atención!',
      })
      // isConfirmed = true
      console.log({ isConfirmed })
      if (isConfirmed) {
        try {
          await this.deleteTreatment(this.treatment)
        } catch (error) {
          errorOnDataSave({ context: this, error })
        }
        // try {
        //   let obj = {}
        //   obj.plot = this.treatment.paleta
        //   obj.nlin = this.treatment.nlineacontrato
        //   await deleteTreatment(obj)
        // } catch (error) {
        //   errorOnDataSave({ context: this, error })
        // }
      }
    },
  },
}
</script>
<style></style>
