<template>
  <b-container fluid>
    <b-row>
      <b-col align-self="center">
        <notice-card :notice="notice" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import NoticeCard from '@/components/cards/NoticeCard'
import { errorOnDataLoad } from '@/services/messageService'
import { fetchNoticeRecipientById } from '@/services/noticesService'

export default {
  name: 'RecipientNoticeDetails',
  components: {
    NoticeCard,
  },
  data() {
    return {
      notice: {},
      isLoading: true,
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      try {
        this.notice = await fetchNoticeRecipientById(
          this.$route.params.id,
          'communication'
        )
        this.isLoading = false
      } catch (error) {
        errorOnDataLoad({ context: this, error })
      }
    },
  },
}
</script>

<style scoped></style>
