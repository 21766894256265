<template>
  <b-container fluid>
    <b-overlay :show="isLoading">
      <control-list-bar
        @searchChange="newSearch => (searchedText = newSearch)"
      />
      <div id="containlist">
        <template v-if="varieties.length">
          <b-row v-for="variety in filteredVarieties" :key="variety.id">
            <b-col align-self="center">
              <variety-card :variety="variety" />
            </b-col>
          </b-row>
        </template>
        <b-row v-else>
          <b-col cols="12" align-self="center">
            <p class="text-center font-weight-bold bg-light text-uppercase">
              No hay variedades disponibles para el contrato seleccionado
              {{ treatmentCreated.plot.paleta }}
            </p>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </b-container>
</template>
<script>
import names from '@/config/names'
import { mapState, mapActions } from 'vuex'
import ControlListBar from '@/components/search/ControlListBar'
import VarietyCard from '@/components/cards/TreatmentVarietyCard'

export default {
  name: 'VarietyList',
  components: {
    VarietyCard,
    ControlListBar,
  },
  data() {
    return {
      searchedText: '',
      names,
      isLoading: true,
    }
  },
  computed: {
    ...mapState('treatments', ['treatmentCreated', 'varieties']),
    filteredVarieties() {
      const filteredVarieties = this.varieties.filter(variety => {
        for (const property in variety) {
          if (
            typeof variety[property] === 'string' &&
            variety[property]
              .toLowerCase()
              .includes(this.searchedText.toLowerCase())
          ) {
            return true
          }
        }
        return false
      })
      // .sort(
      //   (a, b) => new Date(b.fechaaplicacion) - new Date(a.fechaaplicacion)
      // )
      return filteredVarieties
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    ...mapActions('treatments', ['fetchVarieties']),
    loadData: async function () {
      // console.log('En loaddata Variety ' + this.treatmentCreated.plot)
      // await this.fetchVarieties()
      this.isLoading = false
    },
  },
}
</script>

<style scoped>
div#containlist {
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}
@media screen and (max-width: 380px) {
  div#containlist {
    height: 450px;
  }
}
</style>
