<template>
  <transition name="fade" mode="out-in">
    <b-container fluid>
      <!--v-loading="isLoading">  @TODO-->
      <b-row align-h="center">
        <b-col md="10" xs="12">
          <!-- Volver -->
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col
          :md="$route.meta.admin ? 12 : 10"
          xs="12"
          style="padding-top: 1em"
        >
          <router-view />
        </b-col>
      </b-row>
    </b-container>
  </transition>
</template>
<script>
export default {}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter,
  .fade-leave-to
    /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
