<template>
  <b-card no-body class="mb-3" align-self="center">
    <b-card-title class="mt-2">
      <b-container fluid>
        <b-row align-h="between">
          <b-col cols="12">
            <h3>{{ payroll.name }}</h3>
          </b-col>
        </b-row>
      </b-container>
    </b-card-title>
    <b-card-body>
      <b-row>
        <b-col md="8" xs="12">
          <b-card-text>
            <h5>
              {{
                `${payroll.date} ${
                  payroll.endDate ? '- ' + payroll.endDate : ''
                }`
              }}
            </h5>
          </b-card-text>
        </b-col>
        <b-col md="4" xs="12">
          <b-button size="lg" block variant="primary" @click="openPdf()">
            Ver nómina
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>
<script>
import { openFile } from '@/services/filesService.js'
import { errorOnFileLoad } from '@/services/messageService'

export default {
  name: 'PayrollCard',
  props: {
    payroll: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    async openPdf() {
      try {
        const fileProperties = {
          entity: 'payroll',
          entityID: this.payroll.id,
          name: this.payroll.name,
        }
        await openFile(fileProperties)
      } catch (error) {
        errorOnFileLoad({
          context: this,
          error,
        })
      }
    },
  },
}
</script>
<style></style>
