import axios from 'axios'
import { loadToken } from '@/persistence/sessionStorage'

export const httpAuth = axios.create({
  baseURL: process.env.VUE_APP_AUTHENTICATION_SERVICE_URL,
  timeout: 15000,
})

httpAuth.interceptors.request.use(
  async config => {
    config.headers = Object.assign(
      { 'x-access-token': loadToken() },
      config.headers
    )
    return config
  },
  error => Promise.reject(error)
)
