var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-card-body',[_c('b-container',{attrs:{"fluid":""}},[_c('b-form',{ref:"form",attrs:{"lab-width":"150px"}},[_c('b-row',{attrs:{"align-h":"between"}},[_c('b-col',{attrs:{"md":"5","xs":"12"}},[_c('b-form-group',{attrs:{"label":"Tipo de comunicación","label-size":"lg","label-align":"left","label-class":"pl-0"}},[_c('b-form-select',{staticClass:"mb-3",attrs:{"disabled":_vm.block,"options":_vm.typeOptions},model:{value:(_vm.noticeType),callback:function ($$v) {_vm.noticeType=$$v},expression:"noticeType"}})],1),_c('b-form-group',{attrs:{"label":"Título","label-size":"lg","label-class":"pl-0"}},[_c('b-input',{attrs:{"minlength":"5"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('b-form-group',{attrs:{"label":"Descripción","label-size":"lg","label-class":"pl-0"}},[_c('b-form-textarea',{attrs:{"minlength":"5","type":"textarea","autosize":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('b-form-group',{attrs:{"label":"Tipo de documento adjunto","label-size":"lg","label-align":"left","label-class":"pl-0"}},[_c('b-form-radio-group',{staticClass:"pb-3",attrs:{"disabled":_vm.block,"options":_vm.documentOptions},model:{value:(_vm.documentMode),callback:function ($$v) {_vm.documentMode=$$v},expression:"documentMode"}}),(_vm.documentMode === 'unique')?_c('input',{staticClass:"upload",attrs:{"type":"file","name":_vm.nameFile(),"file-count":"$event.target.files.length"},on:{"input":function($event){return _vm.setFileInfo({
                    fileName: $event.target.name,
                    fileList: $event.target.files,
                    uploaded: false,
                  })}}}):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"5","xs":"12"}},[_c('b-form-group',{attrs:{"label":"Notificación","label-size":"lg","label-class":"pl-0"}},[_c('b-form-checkbox-group',{attrs:{"options":_vm.availableNotifyOptions},model:{value:(_vm.notifyBy),callback:function ($$v) {_vm.notifyBy=$$v},expression:"notifyBy"}})],1),_c('b-form-group',{attrs:{"label":"Opciones adicionales","label-size":"lg","label-class":"pl-0"}},[_c('b-form-checkbox',{attrs:{"disabled":_vm.block,"options":_vm.availableNotifyOptions},model:{value:(_vm.confirmationRequested),callback:function ($$v) {_vm.confirmationRequested=$$v},expression:"confirmationRequested"}},[_vm._v(" Requerir aceptación ")]),_c('b-form-group',{attrs:{"label":"Asistencia","label-size":"lg","label-class":"pl-0 pt-3"}},[_c('b-form-group',{attrs:{"label":"Fecha","label-class":"pl-0"}},[_c('b-form-datepicker',{attrs:{"label-help":"","size":"sm","label-no-date-selected":"Elige una fecha","start-weekday":"1"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('b-form-group',{attrs:{"label":"Hora","label-class":"pl-0"}},[_c('b-form-timepicker',{attrs:{"label-no-date-selected":"Elige una hora","size":"sm","label-close-button":"Cerrar","placeholder":"Hora"},model:{value:(_vm.hour),callback:function ($$v) {_vm.hour=$$v},expression:"hour"}})],1)],1)],1),(_vm.showReport)?_c('b-form-group',{attrs:{"label":"Acta de la convocatoria","label-size":"lg","label-align":"left","label-class":"pl-0"}},[_c('input',{staticClass:"upload",attrs:{"type":"file","name":_vm.nameFile(),"file-count":"$event.target.files.length"},on:{"input":function($event){return _vm.setReportInfo({
                    fileName: $event.target.name,
                    fileList: $event.target.files,
                    uploaded: false,
                  })}}})]):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }