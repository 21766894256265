<template>
  <b-container fluid>
    <b-row class="list-controls">
      <b-col>
        <b-input-group>
          <b-input-group-prepend is-text>
            <i class="fas fa-search" />
          </b-input-group-prepend>
          <b-input
            ref="searchBar"
            placeholder="Buscar"
            class="input-with-select"
            clearable
            @input="setStateSearch"
          />
          <b-button
            size="sm"
            :disabled="!searchedText"
            variant="warning"
            @click="setStateSearch('')"
          >
            <i class="fas fa-redo" />
          </b-button>
          <b-dropdown aria-role="list" class="pl-4">
            <template #button-content>
              <span>Seleccionar roles</span>
            </template>
            <b-dropdown-form>
              <b-form-checkbox-group
                v-model="selectedRoles"
                :options="rolesForFiltering"
                stacked
                @input="setRoles"
              ></b-form-checkbox-group>
            </b-dropdown-form>
          </b-dropdown>
        </b-input-group>
      </b-col>
    </b-row>
    <b-table
      ref="table"
      striped
      bordered
      responsive
      :fields="fields"
      :items="users"
      :busy="isLoading"
      no-local-sorting
      @sort-changed="setStateSort"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner variant="primary" label="Spinning" class="align-middle" />
          <strong>Cargando...</strong>
        </div>
      </template>
      <template #cell(roles)="data">
        <b-form-tag
          v-for="role in data.item.roles"
          :key="role"
          disabled
          :variant="!role.startsWith('removed') ? 'primary' : ''"
        >
          {{ roleNames[role] }}
        </b-form-tag>
      </template>
    </b-table>
    <b-pagination
      v-model="page"
      :total-rows="totalUsers"
      :per-page="quantity"
      align="center"
      first-number
      last-number
      @change="setStatePage"
    />
  </b-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import roleNames from './roleNames'
export default {
  name: 'UsersList',
  data() {
    return {
      fields: [
        { key: 'name', label: 'Nombre', sortable: true },
        { key: 'nif', label: 'NIF', sortable: true },
        { key: 'address', label: 'Dirección' },
        { key: 'phoneNumber', label: 'Teléfono', sortable: true },
        { key: 'email', label: 'Correo', sortable: true },
        { key: 'roles', label: 'Tipo' },
      ],
      roleNames: roleNames,
      rolesForFiltering: [],
      isLoading: false,
      selectedRoles: [],
      timer: null,
    }
  },
  computed: {
    ...mapState('users', [
      'users',
      'page',
      'quantity',
      'totalUsers',
      'sortOrder',
      'sortKey',
      'searchedText',
    ]),
  },
  mounted() {
    this.loadData()
  },
  methods: {
    ...mapMutations('users', ['setSort', 'setSearch']),
    ...mapActions('users', ['fetchUsers', 'setPage']),
    async loadData() {
      if (!this.users.length) {
        await this.setStatePage(1)
      }
      this.rolesForFiltering = this.parseRoles()
      this.isLoading = false
    },
    async setStatePage(currentPage) {
      this.isLoading = true
      await this.setPage({
        page: currentPage,
        search: this.searchedText,
        roles: this.selectedRoles,
      })
      this.isLoading = false
    },
    async setStateSort(sort) {
      this.setSort(sort)
      await this.setStatePage(1)
    },
    async setStateSearch(searchedText) {
      if (!searchedText) {
        this.$refs.searchBar.localValue = ''
      }
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.setSearch(searchedText)
      this.timer = setTimeout(async () => {
        await this.setStatePage(1)
      }, 1500)
    },
    async setRoles() {
      this.timer = setTimeout(async () => {
        await this.setStatePage(1)
      }, 1500)
    },
    parseRoles() {
      const roles = []
      for (const role in roleNames) {
        roles.push({ value: role, text: roleNames[role] })
      }
      return roles
    },
  },
}
</script>

<style scoped>
.list-controls {
  margin-top: 1em;
  margin-bottom: 1em;
}
</style>
