import { fetchAllUsers } from '@/services/usersService'

export const usersModule = {
  namespaced: true,
  state: {
    users: [],
    allUsers: [],
    quantity: 15,
    page: 1,
    totalUsers: 0,
    sortKey: undefined,
    sortOrder: undefined,
    searchedText: '',
  },
  mutations: {
    setUsers(state, query) {
      state.users = query.users
      state.totalUsers = query.totalUsers
    },
    setAllUsers(state, users) {
      state.allUsers = users
    },
    setPage(state, page) {
      state.page = page
    },
    setSort(state, { sortBy, sortDesc }) {
      state.sortKey = sortBy
      state.sortOrder = sortDesc ? 'descending' : 'ascending'
    },
    setSearch(state, searchedText) {
      state.searchedText = searchedText
    },
  },
  actions: {
    setPage: async ({ commit, state }, { page, search, roles }) => {
      const query = await fetchAllUsers({
        page,
        quantity: state.quantity,
        search,
        sortKey: state.sortKey,
        sortOrder: state.sortOrder,
        roles,
        multipleRoles: true,
      })
      if (query.users) {
        commit('setUsers', query)
        commit('setPage', page)
      }
    },
  },
  getters: {},
}
