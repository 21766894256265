<template>
  <div
    class="sidebar"
    :style="sidebarStyle"
    :data-color="backgroundColor"
    :data-image="backgroundImage"
  >
    <div class="sidebar-wrapper">
      <div class="logo">
        <a href="#" class="simple-text logo__container">
          <div class="logo-img">
            <img src="../../assets/img/logotop.png" alt="" />
          </div>
          {{ title }}
        </a>
      </div>

      <slot name="content" />
      <ul class="nav nav-main__links">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <slot>
          <sidebar-link
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :to="link.path"
            :link="link"
            @click="closeNavbar"
          >
            <i :class="link.icon" />
            <p>{{ link.name }}</p>
          </sidebar-link>
        </slot>
      </ul>
      <ul v-if="$slots['bottom-links']" class="nav nav-bottom">
        <slot name="bottom-links" />
      </ul>
    </div>
  </div>
</template>
<script>
import SidebarLink from './SidebarLink.vue'

export default {
  components: {
    SidebarLink,
  },
  provide() {
    return {
      autoClose: this.autoClose,
    }
  },
  props: {
    title: {
      type: String,
      default: 'SAT LA PLANA 9422',
    },
    backgroundColor: {
      type: String,
      default: 'black',
      validator: value => {
        const acceptedValues = [
          '',
          'blue',
          'azure',
          'green',
          'orange',
          'red',
          'purple',
          'black',
        ]
        return acceptedValues.indexOf(value) !== -1
      },
    },
    backgroundImage: {
      type: String,
      default: 'img/sidebar-background.jpg',
    },
    activeColor: {
      type: String,
      default: 'success',
      validator: value => {
        const acceptedValues = [
          'primary',
          'info',
          'success',
          'warning',
          'danger',
        ]
        return acceptedValues.indexOf(value) !== -1
      },
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`,
      }
    },
  },
}
</script>
<style>
.sidebar .sidebar-wrapper {
  display: flex;
  flex-direction: column;
}
.sidebar .nav-main__links {
  flex: 1;
}
.sidebar .sidebar-wrapper .logo .logo__container {
  padding-left: 10px;
}
</style>
