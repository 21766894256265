<template>
  <b-row class="list-controls">
    <b-col cols="12">
      <b-input-group>
        <b-input-group-prepend is-text>
          <i class="fas fa-search" />
        </b-input-group-prepend>
        <b-input
          v-model="searchedText"
          placeholder="Buscar"
          class="input-with-select"
          clearable
        />
      </b-input-group>
    </b-col>
  </b-row>
</template>
<script>
export default {
  data() {
    return {
      searchedText: '',
    }
  },
  watch: {
    searchedText: function () {
      this.onSearchChange()
    },
  },
  methods: {
    onSearchChange() {
      this.$emit('searchChange', this.searchedText)
    },
  },
}
</script>
<style scoped>
.list-controls {
  margin-top: 1em;
  margin-bottom: 1em;
}
</style>
