<template>
  <div>Estás siendo redirigido a la página de Navision</div>
</template>
<script>
export default {
  name: 'NavisionPage',
  mounted() {
    window.open('http://10.0.102.2:8080/BaguGestionFrutasBC130/')
    this.$router.go(-1)
  },
}
</script>
