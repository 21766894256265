<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <h3>Destinatarios</h3>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12">
        <h5>Seleccionar grupo</h5>
        <b-row>
          <b-col cols="2">
            <b-button
              variant="outline-primary"
              class="mr-3"
              block
              :disabled="contracts"
              @click="addPartners()"
            >
              Seleccionar socios
            </b-button>
          </b-col>
          <b-col cols="2">
            <b-button
              variant="outline-primary"
              class="mr-3"
              block
              :disabled="contracts"
              @click="addOwners()"
            >
              Seleccionar propietarios
            </b-button>
          </b-col>
          <b-col cols="2">
            <b-button
              variant="outline-primary"
              class="mr-3"
              :disabled="contracts"
              block
              @click="addEmployees()"
            >
              Seleccionar empleados
            </b-button>
          </b-col>
          <b-row />
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6" xs="12">
        <h5>Destinatarios disponibles</h5>
        <h6>Filtrado por rol</h6>
        <b-form-checkbox-group
          v-model="availableOptions.filters.role"
          :options="roleFilter"
          @input="onAvailableSearchChange()"
        />
        <b-input-group class="mb-3">
          <b-input-group-prepend is-text>
            <i class="fas fa-search" />
          </b-input-group-prepend>
          <b-input
            v-model="availableOptions.search"
            placeholder="Buscar"
            class="input-with-select"
            clearable
            @input="onAvailableSearchChange()"
          />
        </b-input-group>
        <b-row />
        <b-table
          ref="available"
          selectable
          selected-variant="primary"
          sticky-header="500px"
          striped
          bordered
          :busy="availableOptions.isLoading"
          :items="available.users"
          :fields="userFields"
          class="left-table"
          no-local-sorting
          @row-selected="onAvailableSelected"
          @sort-changed="onAvailableSortChange"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner
                variant="primary"
                label="Spinning"
                class="align-middle"
              />
              <strong>Cargando...</strong>
            </div>
          </template>
          <template #cell(roles)="data">
            <div>
              <b-badge v-for="role in data.item.roles" :key="role" class="mr-3">
                {{ roleNames[role] }}
              </b-badge>
            </div>
          </template>
          <template #cell(partner.partnerNumber)="data">
            <span v-if="data.item.partner && data.item.partner.partnerNumber">
              {{ data.item.partner.partnerNumber }}
            </span>
          </template>
        </b-table>
        <b-pagination
          v-model="availableOptions.page"
          :total-rows="available.totalUsers"
          :per-page="availableOptions.itemsPerPage"
          align="center"
          first-number
          last-number
          @input="onAvailablePageChange"
        />
      </b-col>

      <b-col md="6" xs="12">
        <div class="transfer-buttons">
          <b-button-group vertical>
            <b-button
              class="mb-3"
              @click="
                availableSelection.length
                  ? moveUsersToSelected()
                  : notifyQueryAdd()
              "
            >
              <i class="fas fa-arrow-right" />
            </b-button>
            <b-button
              class="mb-3"
              @click="
                selectedSelection.length
                  ? moveUsersToAvailable()
                  : notifyQueryRemove()
              "
            >
              <i class="fas fa-arrow-left" />
            </b-button>
          </b-button-group>
        </div>
        <div class="pl-3">
          <h5>Destinatarios</h5>
          <h6>Filtrado por rol</h6>
          <b-form-checkbox-group
            v-model="selectedOptions.filters.role"
            :options="roleFilter"
            @input="onSelectedSearchChange"
          />
          <b-form-group>
            <b-input-group class="mb-3">
              <b-input-group-prepend is-text>
                <i class="fas fa-search" />
              </b-input-group-prepend>
              <b-input
                v-model="selectedOptions.search"
                placeholder="Buscar"
                class="input-with-select"
                clearable
                @input="onSelectedSearchChange"
              />
              <b-button
                class="ml-3"
                variant="warning"
                @click="removeAllSelected"
              >
                Reiniciar
              </b-button>
            </b-input-group>
            <b-table
              ref="recipient"
              selectable
              selected-variant="primary"
              sticky-header="500px"
              striped
              bordered
              :busy="selectedOptions.isLoading"
              :items="selected.recipients"
              :fields="userFields"
              class="left-table"
              no-local-sorting
              @row-selected="onSelectedSelection"
              @sort-changed="onSelectedSortChange"
            >
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner
                    variant="primary"
                    label="Spinning"
                    class="align-middle"
                  />
                  <strong>Cargando...</strong>
                </div>
              </template>
              <template #cell(roles)="data">
                <div>
                  <b-badge
                    v-for="role in data.item.roles"
                    :key="role"
                    class="mr-3"
                  >
                    {{ roleNames[role] }}
                  </b-badge>
                </div>
              </template>
              <template #cell(partner.partnerNumber)="data">
                <span
                  v-if="data.item.partner && data.item.partner.partnerNumber"
                >
                  {{ data.item.partner.partnerNumber }}
                </span>
              </template>
            </b-table>
            <b-pagination
              v-model="selectedOptions.page"
              :total-rows="selected.totalRecipients"
              :per-page="selectedOptions.itemsPerPage"
              align="center"
              first-number
              last-number
              @input="onSelectedPageChange"
            />
          </b-form-group>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import roleNames from '@/pages/users/roleNames.json'
import { mapState } from 'vuex'
export default {
  name: 'TransferUsers',
  props: {
    contracts: { type: Boolean, default: false },
    selected: {
      type: Object,
      default() {
        return { users: [], totalUsers: 0 }
      },
    },
    available: {
      type: Object,
      default() {
        return { users: [], totalUsers: 0 }
      },
    },
    refresh: { type: Boolean, default: false },
  },
  data() {
    return {
      userFields: [
        { key: 'code', label: 'Cod', sortable: true },
        { key: 'name', label: 'Nombre', sortable: true },
        { key: 'roles', label: 'Roles' },
        { key: 'partnerNumber', label: 'Nº Socio', sortable: true },
      ],
      roleFilter: [
        { text: 'Socios', value: 'partner' },
        { text: 'Propietarios', value: 'owner' },
        { text: 'Empleados', value: 'employee' },
      ],
      roleNames,
      selectedOptions: {
        sortOrder: 'ascending',
        sortKey: 'name',
        search: '',
        filters: {
          date: [],
          role: [],
        },
        isLoading: false,
        itemsPerPage: 50,
        page: 1,
      },
      availableOptions: {
        sortOrder: 'ascending',
        sortKey: 'name',
        search: '',
        filters: {
          date: [],
          role: [],
        },
        isLoading: false,
        itemsPerPage: 50,
        page: 1,
      },
      availableSelection: [],
      selectedSelection: [],
      searchDates: [],
      timer: {
        availableSearch: null,
        selectedSearch: null,
      },
    }
  },
  computed: {
    ...mapState('notices', ['addedNotice']),
  },
  watch: {
    available: {
      handler() {
        this.availableOptions.isLoading = false
      },
      deep: true,
    },
    selected: {
      handler() {
        this.selectedOptions.isLoading = false
      },
      deep: true,
    },
    refresh: {
      handler(needsRefresh) {
        if (needsRefresh) {
          this.onRefresh()
        }
      },
    },
  },
  methods: {
    notifySendNotification() {
      this.$emit('publish')
    },
    notifyAvailableQueryChange() {
      this.$emit('availableQueryChange', this.getAvailableQuery())
      this.availableOptions.isLoading = true
    },
    notifySelectedQueryChange() {
      this.$emit('selectedQueryChange', this.getSelectedQuery())
      this.selectedOptions.isLoading = true
    },
    notifyUsersAdd() {
      this.$emit(
        'usersAdd',
        this.availableSelection.map(user => user.id)
      )
    },
    notifyQueryAdd() {
      this.$emit('queryAdd', this.getAvailableQuery())
    },
    notifyQueryRoleAdd(role) {
      this.$emit('queryAdd', { roles: [role], contractOps: this.contracts })
    },
    notifyUsersRemove() {
      this.$emit(
        'usersRemove',
        this.selectedSelection.map(user => user.id)
      )
    },
    notifyAllUsersRemove() {
      this.$emit('usersRemove')
    },
    notifyQueryRemove() {
      this.$emit('queryRemove', this.getSelectedQuery())
    },
    addPartners() {
      this.notifyQueryRoleAdd('partner')
    },
    addOwners() {
      this.notifyQueryRoleAdd('owner')
    },
    addEmployees() {
      this.notifyQueryRoleAdd('employee')
    },
    getAvailableQuery() {
      return this.getQuery(this.availableOptions)
    },
    getSelectedQuery() {
      return this.getQuery(this.selectedOptions)
    },
    getQuery(itemOptions) {
      const { page, itemsPerPage, search, sortOrder, sortKey, filters } =
        itemOptions
      return {
        page,
        quantity: itemsPerPage,
        search,
        sortKey,
        sortOrder,
        roles: filters.role,
        contractOps: this.contracts,
        // dates: this.searchDates.map(dat => new Date(dat))
      }
    },
    onAvailablePageChange(currentPage) {
      this.availableOptions.page = currentPage
      this.notifyAvailableQueryChange()
    },
    onSelectedPageChange(currentPage) {
      this.selectedOptions.page = currentPage
      this.notifySelectedQueryChange()
    },
    onAvailableSortChange({ sortBy, sortDesc }) {
      this.availableOptions.sortKey = sortBy
      this.availableOptions.sortOrder = sortDesc ? 'descending' : 'ascending'
      this.notifyAvailableQueryChange()
    },
    onSelectedSortChange({ sortBy, sortDesc }) {
      this.selectedOptions.sortKey = sortBy
      this.selectedOptions.sortOrder = sortDesc ? 'descending' : 'ascending'
      this.notifySelectedQueryChange()
    },
    onAvailableSelected(items) {
      this.availableSelection = items
    },
    onSelectedSelection(items) {
      this.selectedSelection = items
    },
    moveUsersToSelected() {
      this.notifyUsersAdd()
    },
    moveUsersToAvailable() {
      this.notifyUsersRemove()
    },
    removeAllSelected() {
      // @TODO decide how to notify a total remove
      this.notifyAllUsersRemove()
    },
    onAvailableSearchChange() {
      const searchOptions = {
        timer: this.timer.availableSearch,
        actionOnTimeout: this.notifyAvailableQueryChange,
      }
      this.timer.availableSearch = this.search(searchOptions)
    },
    onSelectedSearchChange() {
      const searchOptions = {
        timer: this.timer.selectedSearch,
        actionOnTimeout: this.notifySelectedQueryChange,
      }
      this.timer.selectedSearch = this.search(searchOptions)
    },
    search({ timer, actionOnTimeout }) {
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(async () => {
        actionOnTimeout()
      }, 500)
      return timer
    },
    onRefresh() {
      this.notifyAvailableQueryChange()
      this.notifySelectedQueryChange()
      this.$emit('refreshRequested')
    },
  },
}
</script>

<style scoped>
@media (max-height: 950px) {
  .left-table {
    max-height: 100px;
  }
  .right-table {
    max-height: 180px;
  }
}

@media (min-height: 950px) {
  .left-table {
    max-height: 360px;
  }
  .right-table {
    max-height: 400px;
  }
}

.transfer-buttons {
  position: absolute;
  margin-left: -1.6em;
  top: 50%;
}
</style>
