import '@/filters/time'
import Vue from 'vue'
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import VueRouter from 'vue-router'
import App from './App.vue'
import './assets/css/custom.css' // @TODO put in a module
import LightBootstrap from './light-bootstrap-main'
import './plugins/bootstrap-vue'
import './plugins/sentry'
import router from './router'
import store from './store/store'

// plugin setup
Vue.use(VueRouter)
Vue.use(LightBootstrap)
Vue.use(VueFormWizard)

Vue.config.devtools = true
/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store,
})
