<template>
  <b-container fluid>
    <b-row align-h="end">
      <b-col cols="12">
        <b-button block variant="primary" :to="'documentacion/subir'">
          Subir documento
        </b-button>
      </b-col>
    </b-row>
    <control-list-bar @searchChange="newSearch => (searchedText = newSearch)" />
    <template v-if="documents.length">
      <b-row v-for="document in filteredDocuments" :key="document.id">
        <b-col align-self="center">
          <doc-card :document="document" :is-admin="isAdmin" />
        </b-col>
      </b-row>
    </template>
    <b-row v-else>
      <b-col cols="12" align-self="center">
        <p class="text-center font-weight-bold bg-light text-uppercase">
          No hay documentos disponibles aún
        </p>
        <p class="text-center bg-light text-uppercase">
          Puedes subir documentos usando el botón de arriba
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import DocCard from '@/components/cards/DocCard'
import ControlListBar from '@/components/search/ControlListBar'

export default {
  name: 'DocsList',
  components: {
    DocCard,
    ControlListBar,
  },
  data() {
    return {
      dialogVisible: false,
      searchedText: '',
      isAdmin: false,
    }
  },
  computed: {
    ...mapState('documentation', ['documents']),
    ...mapState('auth', ['userId', 'userRoles']),
    filteredDocuments() {
      return this.documents
        .filter(document => {
          for (const property of Object.values(document)) {
            if (
              typeof property === 'string' &&
              property.toLowerCase().includes(this.searchedText.toLowerCase())
            ) {
              return true
            }
          }
        })
        .sort((a, b) => new Date(b.date) - new Date(a.date))
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    ...mapActions('documentation', ['fetchDocuments']),
    async loadData() {
      await this.fetchDocuments(this.userId)
      this.isAdmin = this.userRoles.includes('adm720')
    },
  },
}
</script>
