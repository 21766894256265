import { httpApi } from '@/services/http/httpApi'

export const fetchAllPayrolls = async () => {
  try {
    const response = await httpApi.get('/payrolls')
    if (response.status === 200) {
      return response.data
    }
  } catch (err) {
    return []
  }
}

export const fetchPayrollById = async postId => {
  try {
    const response = await httpApi.get(`/payrolls/${postId}`)
    if (response.status === 200) {
      return response.data
    }
  } catch (err) {
    return []
  }
}

export const addPayroll = async payroll => {
  try {
    const response = httpApi.post('/payrolls', payroll)
    if (response.status === 201) {
      return response.data
    }
  } catch (err) {
    return []
  }
}
export const updatePayroll = async payroll => {
  try {
    const response = await httpApi.put(`/payrolls/${payroll.id}`, payroll)
    if (response.status === 200) {
      return response.data
    }
  } catch (err) {
    return []
  }
}
export const deletePayroll = async payroll => {
  try {
    const response = await httpApi.delete(`/payrolls/${payroll.id}`)
    if (response.status === 200) {
      return response.data
    }
  } catch (err) {
    return []
  }
}

export const fetchUserPayrolls = async payroll => {
  try {
    const response = await httpApi.get(`/payrolls/${payroll.id}`, payroll)
    if (response.status === 200) {
      return response.data
    }
  } catch (err) {
    return []
  }
}
