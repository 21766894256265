<template>
  <b-form
    class="login-form"
    label-position="top"
    label-width="100px"
    :model="recoveryData"
  >
    <h5>Por favor, proporcione los siguientes datos</h5>
    <b-form-group label="NIF">
      <b-form-input
        v-model="recoveryData.username"
        placeholder="NIF"
        sufix-icon="el-icon-bank-card"
        minlength="9"
        maxlength="9"
      />
    </b-form-group>
    <b-form-group v-show="recoveryData.username.length === 9" label="Telefono">
      <b-form-input
        v-model="recoveryData.phone"
        placeholder="Telefono"
        minlength="9"
        maxlength="9"
        @keyup.enter.native="checkRecoveryData()"
      />
    </b-form-group>

    <b-form-group>
      <b-button
        variant="primary"
        class="mr-3"
        :disabled="!isInputValid"
        @click="checkRecoveryData()"
      >
        Comprobar datos
      </b-button>
      <b-button size="small" @click="setMode('login')"> Cancelar </b-button>
    </b-form-group>
  </b-form>
</template>

<script>
import { checkRecoveryData } from '@/services/authService'
import {
  errorOnLoginRecoveryFieldsNeeded,
  errorOnLoginRecoveryCheckingData,
} from '@/services/messageService'
import { mapMutations } from 'vuex'

export default {
  name: 'InitialRecoveryForm',
  data() {
    return {
      recoveryData: {
        username: '',
        phone: '',
      },
      isTouched: false,
      error: false,
    }
  },
  computed: {
    isInputValid() {
      return (
        this.recoveryData.username.match(/^\d{8}[a-zA-Z]$/) &&
        this.recoveryData.phone.match(/^(6|7|8)\d{8}$/)
      )
    },
    hasErrors() {
      return this.isTouched && !this.isInputValid
    },
  },
  methods: {
    ...mapMutations('auth', ['setMode']),
    async checkRecoveryData() {
      if (this.isInputValid) {
        try {
          const recoverySuccessfully = await checkRecoveryData(
            this.recoveryData
          )
          if (recoverySuccessfully) {
            this.setMode('password')
          } else {
            errorOnLoginRecoveryFieldsNeeded({ context: this })
          }
        } catch (error) {
          errorOnLoginRecoveryCheckingData({ context: this, error })
        }
      } else {
        errorOnLoginRecoveryFieldsNeeded({ context: this })
      }
    },
  },
}
</script>

<style scoped>
.login-form {
  width: 80%;
  margin-left: 10%;
}
</style>
