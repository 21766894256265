var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"mb-3",attrs:{"no-body":""}},[_c('b-card-title',{staticClass:"mt-2"},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{attrs:{"align-h":"between"}},[_c('b-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.contract.name))])])],1)],1)],1),_c('b-card-sub-title',[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{attrs:{"align-h":"between"}},[_c('b-col',{attrs:{"md":"8","sm":"12"}},[_c('span',[_vm._v(_vm._s(_vm._f("toDateTime")(_vm.contract.date)))])])],1)],1)],1),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"8","xs":"12"}},[_c('p',[_vm._v(_vm._s(_vm.contract.description))])]),_c('b-col',{attrs:{"md":"4","xs":"12"}},[_c('b-button',{attrs:{"block":"","variant":_vm.contract.hasFile || _vm.contract.hasCommunicationFile ? 'primary' : '',"size":"lg","disabled":!_vm.contract.hasFile && !_vm.contract.hasCommunicationFile},on:{"click":function($event){return _vm.openPdf()}}},[_vm._v(" "+_vm._s(_vm.contract.hasFile || _vm.contract.hasCommunicationFile ? 'Ver contrato' : 'Sin fichero')+" ")])],1)],1)],1),(_vm.contract.seen || _vm.contract.confirmationRequested)?_c('b-card-footer',[_c('b-row',[(_vm.contract.confirmationRequested)?[_c('b-col',{attrs:{"md":"6","xs":"12"}},[(
              !_vm.contract.confirmed || (_vm.contract.confirmed && _vm.contract.accepted)
            )?_c('b-button',{staticClass:"mr-3",attrs:{"block":"","size":"lg","disabled":_vm.contract.confirmed,"variant":_vm.contract.confirmed
                ? _vm.contract.accepted
                  ? 'success'
                  : ''
                : 'success'},on:{"click":function($event){return _vm.processAcceptContract()}}},[_vm._v(" "+_vm._s(_vm.contract.confirmed ? _vm.contract.accepted ? 'Aceptado' : '' : 'Acepto')+" ")]):_vm._e()],1),_c('b-col',{attrs:{"md":"6","xs":"12"}},[(
              !_vm.contract.confirmed ||
              (_vm.contract.confirmed && !_vm.contract.accepted)
            )?_c('b-button',{attrs:{"size":"lg","block":"","disabled":_vm.contract.confirmed,"variant":_vm.contract.confirmed
                ? !_vm.contract.accepted
                  ? 'danger'
                  : ''
                : 'danger'},on:{"click":function($event){return _vm.processRejectContract()}}},[_vm._v(" "+_vm._s(_vm.contract.confirmed ? !_vm.contract.accepted ? 'No aceptado' : '' : 'No acepto')+" ")]):_vm._e()],1),(_vm.contract.confirmed)?_c('b-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"card-footer-notes"},[_vm._v(" "+_vm._s(_vm.contract.accepted ? 'Aceptado' : 'Rechazado')+" el "+_vm._s(_vm._f("toDateTime")(_vm.contract.confirmationDate))+" ")])]):_vm._e()]:_vm._e(),(_vm.contract.seen)?_c('b-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"mt-2 mb-0 card-footer-notes"},[_vm._v(" "+_vm._s(_vm.contract.seen ? 'Visto' : 'No visto')+" el "+_vm._s(_vm._f("toDateTime")(_vm.contract.seenDate))+" ")])]):_vm._e()],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }