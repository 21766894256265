<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col align-self="center">
        <b-container>
          <b-input-group class="mb-3" align-self="center">
            <b-input-group-prepend is-text>
              <i class="fas fa-search" />
            </b-input-group-prepend>
            <b-input
              v-model="selectedOptions.search"
              placeholder="Buscar"
              class="input-with-select"
              clearable
              @input="onSelectedSearchChange"
            />
          </b-input-group>
          <b-table
            ref="recipient"
            sticky-header="500px"
            striped
            bordered
            :busy="selectedOptions.isLoading"
            :items="selected.recipients"
            :fields="userFields"
            class="left-table"
            no-local-sorting
            @sort-changed="onSelectedSortChange"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner
                  variant="primary"
                  label="Spinning"
                  class="align-middle"
                />
                <strong>Cargando...</strong>
              </div>
            </template>
            <template #cell(roles)="data">
              <div>
                <b-badge
                  v-for="role in data.item.roles"
                  :key="role"
                  class="mr-3"
                >
                  {{ roleNames[role] }}
                </b-badge>
              </div>
            </template>
            <template #cell(partner.partnerNumber)="data">
              <span v-if="data.item.partner && data.item.partner.partnerNumber">
                {{ data.item.partner.partnerNumber }}
              </span>
            </template>
            <template #cell(id)="data">
              <b-button
                block
                variant="primary"
                size="sm"
                @click="openPdf(data.item.id)"
              >
                Ver contrato
              </b-button>
            </template>
          </b-table>
          <b-pagination
            v-model="selectedOptions.page"
            :total-rows="selected.totalRecipients"
            :per-page="selectedOptions.itemsPerPage"
            align="center"
            first-number
            last-number
            @input="onSelectedPageChange"
          />
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import roleNames from '@/pages/users/roleNames.json'
import { mapState } from 'vuex'
export default {
  name: 'UsersReview',
  props: {
    queryReview: { type: Boolean, default: false },
    selected: {
      type: Object,
      default() {
        return { users: [], totalUsers: 0 }
      },
    },
    refresh: { type: Boolean, default: false },
    contracts: { type: Boolean, default: false },
  },
  data() {
    return {
      roleFilter: [
        { text: 'Socios', value: 'partner' },
        { text: 'Propietarios', value: 'owner' },
        { text: 'Empleados', value: 'employee' },
      ],
      roleNames,
      baseFields: [
        { key: 'code', label: 'Código', sortable: true },
        { key: 'name', label: 'Nombre', sortable: true },
        { key: 'roles', label: 'Roles' },
      ],
      selectedOptions: {
        sortOrder: 'ascending',
        sortKey: 'name',
        search: '',
        isLoading: false,
        itemsPerPage: 50,
        page: 1,
      },
      timer: {
        selectedSearch: null,
      },
    }
  },
  computed: {
    ...mapState('notices', ['addedNotice']),
    userFields() {
      const fields = [...this.baseFields]
      if (
        this.selected.recipients &&
        this.selected.recipients.some(
          rec => rec.partner && rec.partner.partnerNumber
        )
      ) {
        fields.push({
          key: 'partner',
          label: 'Nº Socio',
          sortable: true,
        })
      }
      if (this.contracts) {
        fields.push({ key: 'id', label: 'Contratos' })
      }
      return fields
    },
  },
  watch: {
    selected: {
      handler() {
        this.selectedOptions.isLoading = false
      },
      deep: true,
    },
    refresh: {
      handler(needsRefresh) {
        if (needsRefresh) {
          this.onRefresh()
        }
      },
    },
  },
  methods: {
    notifySelectedQueryChange() {
      this.$emit('selectedQueryChange', this.getQuery())
      this.selectedOptions.isLoading = true
    },
    getQuery() {
      const { page, itemsPerPage, search, sortOrder, sortKey } =
        this.selectedOptions
      return {
        page,
        quantity: itemsPerPage,
        search,
        sortKey,
        sortOrder,
      }
    },
    onSelectedSortChange({ sortBy, sortDesc }) {
      this.selectedOptions.sortKey = sortBy
      this.selectedOptions.sortOrder = sortDesc ? 'descending' : 'ascending'
      this.notifySelectedQueryChange()
    },
    onSelectedSearchChange() {
      this.timer.selectedSearch = this.search(this.timer.selectedSearch)
    },
    onSelectedPageChange(currentPage) {
      this.selectedOptions.page = currentPage
      this.notifySelectedQueryChange()
    },
    search(timer) {
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(async () => {
        this.notifySelectedQueryChange()
      }, 1500)
      return timer
    },
    onRefresh() {
      this.notifySelectedQueryChange()
      this.$emit('refreshRequested')
    },
    async openPdf(userId) {
      this.$emit('openContract', userId)
    },
  },
}
</script>

<style scoped>
@media (max-height: 950px) {
  .left-table {
    max-height: 100px;
  }
  .right-table {
    max-height: 180px;
  }
}

@media (min-height: 950px) {
  .left-table {
    max-height: 360px;
  }
  .right-table {
    max-height: 400px;
  }
}
</style>
