<template>
  <div class="wrapper">
    <side-bar>
      <sidebar-link :to="'/mi-perfil'">
        <i class="far fa-user" />
        <p>Mi perfil</p>
      </sidebar-link>

      <sidebar-link :to="'/cerrar-sesion'">
        <i class="fas fa-sign-out-alt" />
        <p>Cerrar sesión</p>
      </sidebar-link>
      <hr style="border-top: 1px solid #fafafa" />
      <sidebar-link
        v-for="route in sideMenu"
        :key="route.name"
        :to="route.path"
        :style="route.meta.sub ? 'margin-left: 1rem;' : ''"
      >
        <i class="fas" :class="'fa-' + route.meta.icon" />
        <p>{{ menuTexts[route.meta.title] }}</p>
      </sidebar-link>
    </side-bar>
    <div class="main-panel">
      <top-navbar />

      <dashboard-content @click="toggleSidebar" />

      <content-footer />
    </div>
  </div>
</template>

<script>
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import DashboardContent from './Content.vue'
// import BottomNavbar from './BottomNavbar.vue'
import { generateSideMenu } from '@/router/routes'
import menuTexts from '@/config/names'
import { isUserAuthenticated } from '@/persistence/sessionStorage'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'DashBoardLayout',
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    //  BottomNavbar
  },
  data() {
    return {
      sideMenu: generateSideMenu(),
      menuTexts,
      ready: false,
      authenticated: false,
    }
  },
  computed: {
    ...mapState('auth', ['userId']),
  },
  mounted() {
    this.sideMenu = generateSideMenu()
    this.authenticated = isUserAuthenticated()
    if (this.authenticated) {
      this.loadUser()
    }
    this.ready = true
  },
  methods: {
    ...mapActions('auth', ['loadUser']),
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    },
  },
}
</script>
<style lang="scss"></style>
