import { httpApi } from './http/httpApi'

export const fetchDocuments = async () => {
  try {
    const response = await httpApi.get('/documents')
    if (response.status === 200) {
      return response.data
    }
  } catch (err) {
    return null
  }
}

export const createDocument = async document => {
  try {
    const response = await httpApi.post('/documents', document)
    if (response.status === 201) {
      return response.data
    }
  } catch (err) {
    return null
  }
}

export const deleteDocument = async documentId => {
  try {
    const response = await httpApi.delete(`/documents/${documentId}`)
    if (response.status === 200) {
      return true
    }
    return false
  } catch (err) {
    return false
  }
}
