<template>
  <div class="login-form">
    <b-form
      class="login-form"
      label-position="top"
      label-width="100px"
      :model="passwordData"
    >
      <template v-if="!verified && options && !options.local">
        <h5>
          Por favor, introduzca el código de verificación que le ha llegado al
          movil
        </h5>
        <b-form-group label="Código de verificacion">
          <b-form-input
            v-model="recoveryToken"
            placeholder="Código de verificacion"
          />
        </b-form-group>
      </template>
      <template v-else>
        <b-form-group>
          <hr />
        </b-form-group>
        <h5>Por favor, establezca una nueva contraseña</h5>
        <b-form-group label="Contraseña">
          <b-form-input
            v-model="passwordData.password"
            placeholder="Contraseña"
            type="password"
            sufix-icon="el-icon-bank-card"
            class="is-rounded"
            @focus="onFocus"
          />
        </b-form-group>
        <b-form-group label="Repita su contraseña">
          <b-form-input
            v-model="passwordData.repeatedPassword"
            placeholder="Repita su contraseña"
            type="password"
            @keyup.enter.native="changePassword()"
          />
        </b-form-group>
        <b-form-group>
          <b-button variant="primary" @click="changePassword()">
            Cambiar constraseña
          </b-button>
        </b-form-group>
      </template>
    </b-form>
  </div>
</template>

<script>
import {
  changePassword,
  changePasswordByToken,
  verifyRecoveryToken,
} from '@/services/authService'
import {
  errorOnPasswordChangeInvalid,
  errorOnDataSave,
  errorOnPasswordChangeFieldsNeeded,
} from '@/services/messageService'
import { isUserAuthenticated } from '@/persistence/sessionStorage'

import { mapMutations, mapState } from 'vuex'

export default {
  name: 'PasswordChange',
  data() {
    return {
      passwordData: {
        password: '',
        repeatedPassword: '',
      },
      recoveryToken: '',
      error: false,
      verified: false,
    }
  },
  computed: {
    ...mapState('auth', ['options']),
    isInputValid() {
      return (
        this.passwordData.password !== '' &&
        this.passwordData.password === this.passwordData.repeatedPassword
      )
    },
    hasErrors() {
      return this.isTouched && !this.isInputValid
    },
  },
  watch: {
    recoveryToken() {
      if (this.recoveryToken.match(/^[0-9]{6}$/)) {
        this.verifyRecoveryToken()
      }
    },
  },
  methods: {
    ...mapMutations('auth', ['setMode']),
    async verifyRecoveryToken() {
      this.verified = await verifyRecoveryToken(this.recoveryToken)
    },
    async changePassword() {
      if (this.isInputValid) {
        try {
          const changedSuccessfully = this.options.local
            ? await changePassword({ password: this.passwordData.password })
            : await changePasswordByToken({
                password: this.passwordData.password,
                recoveryToken: this.recoveryToken,
              })
          if (changedSuccessfully) {
            isUserAuthenticated()
              ? this.$router.replace({ path: '/' })
              : this.setMode('login')
          } else {
            errorOnPasswordChangeInvalid({ context: this })
          }
        } catch (error) {
          errorOnDataSave({ context: this, error })
        }
      } else {
        errorOnPasswordChangeFieldsNeeded({ context: this })
      }
    },
    onFocus() {
      this.isTouched = true
    },
  },
}
</script>

<style scoped>
.login-form {
  width: 80%;
  margin-left: 10%;
}
</style>
