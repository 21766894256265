import { httpApi } from '@/services/http/httpApi'

export const fetchAllWithholdings = async () => {
  try {
    const response = await httpApi.get('/withholdings')
    if (response.status === 200) {
      return response.data
    }
  } catch (err) {
    return []
  }
}

export const fetchWithholdingById = async postId => {
  try {
    const response = await httpApi.get(`/withholdings/${postId}`)
    if (response.status === 200) {
      return response.data
    }
  } catch (err) {
    return []
  }
}

export const fetchUserWithholdings = async withholding => {
  try {
    const response = await httpApi.get(
      `/withholdings/${withholding.id}`,
      withholding
    )
    if (response.status === 200) {
      return response.data
    }
  } catch (err) {
    return []
  }
}
