import { loadUserId, loadUserRoles } from '@/persistence/sessionStorage'
import { getMyProfile } from '@/services/authService'

export const authModule = {
  namespaced: true,
  state: {
    mode: 'login',
    userId: -1,
    user: null,
    userRoles: [],
    authError: null,
    options: {
      local: false,
    },
  },
  mutations: {
    setOptions(state, options) {
      state.options = options
    },
    setMode(state, mode) {
      state.mode = mode
    },
    setUserId(state, userId) {
      state.userId = userId
    },
    setUser(state, user) {
      state.user = JSON.parse(JSON.stringify(user))
    },
    setUserRoles(state, userRoles) {
      state.userRoles = userRoles
    },
    setAuthError(state, error) {
      state.authError = error
    },
  },
  actions: {
    loadUser: async ({ commit }) => {
      const userId = parseInt(loadUserId())
      commit('setUserId', userId)
      commit('setUserRoles', loadUserRoles())

      const user = await getMyProfile()
      commit('setUser', user)
    },
    setAuthError: {
      root: true,
      handler({ commit }, error) {
        commit('setAuthError', error)
      },
    },
  },
  getters: {},
}
