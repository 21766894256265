<template>
  <b-container fluid>
    <b-overlay :show="isLoading">
      <form-wizard
        ref="wizard"
        title=""
        subtitle=""
        next-button-text="Siguiente"
        back-button-text="Atras"
        finish-button-text="Notificar"
        color="#3472F7"
        @on-change="checkChanges"
        @on-complete="onSendNotification"
      >
        <tab-content
          title="Detalles de la comunicación"
          :before-change="onSave"
        >
          <sender-notice-form :block="isSaved" :notice="noticeToSave" />
        </tab-content>
        <tab-content title="Selección de destinatarios">
          <b-card>
            <b-card-body>
              <users-transfer
                :selected="selected"
                :available="available"
                :refresh="refresh"
                :contracts="displayContracts"
                @usersAdd="onUsersAdd"
                @queryAdd="onQueryAdd"
                @usersRemove="onUsersRemove"
                @queryRemove="onQueryRemove"
                @availableQueryChange="onAvailablePageChange"
                @selectedQueryChange="onSelectedPageChange"
                @refreshRequested="refresh = false"
              />
            </b-card-body>
          </b-card>
        </tab-content>
        <tab-content title="Revisión de destinatarios">
          <users-review
            :refresh="refreshReview"
            :selected="selectedReview"
            :contracts="displayContracts"
            @selectedQueryChange="onReviewPageChange"
            @refreshRequested="refreshReview = false"
            @openContract="onOpenContract"
          />
        </tab-content>
        <template slot="prev">
          <b-button variant="primary"> Atras </b-button>
        </template>
        <template slot="custom-buttons-left">
          <b-button class="ml-3" variant="danger" @click="remove()">
            Borrar
          </b-button>
        </template>
        <template slot="custom-buttons-right">
          <b-button class="ml-3" variant="warning" @click="close()">
            Volver
          </b-button>
        </template>
        <template slot="next">
          <b-button variant="primary"> Siguiente </b-button>
        </template>
        <template slot="finish">
          <b-button variant="primary"> Publicar </b-button>
        </template>
      </form-wizard>
    </b-overlay>
  </b-container>
</template>

<script>
import SenderNoticeForm from './SenderNoticeForm'
import { mapState, mapActions, mapMutations } from 'vuex'
import { errorOnDataSave, errorOnDataLoad } from '@/services/messageService'
import {
  formatDateForDatepicker,
  formatHourForHourpicker,
  getCurrentHour,
} from '@/services/time'
import {
  fetchAvailableRecipients,
  fetchSelectedRecipients,
  fetchNoticeById,
  publish,
  deleteNotice,
} from '@/services/noticesService'
import UsersTransfer from '@/components/forms/UsersTransfer'
import UsersReview from '@/components/forms/UsersReview'
import { openContract } from '@/services/filesService.js'

export default {
  name: 'SenderNoticeSave',
  components: {
    SenderNoticeForm,
    UsersTransfer,
    UsersReview,
  },
  props: {
    notice: {
      type: Object,
      default() {
        return { id: null }
      },
    },
  },
  data() {
    return {
      title: 'Nueva comunicación',
      startSave: false,
      selected: {},
      available: {},
      selectedReview: {},
      recipientsToSave: [],
      refresh: false,
      refreshReview: false,
      isLoading: true,
      queryReview: false,
    }
  },
  computed: {
    ...mapState('notices', ['addedNotice', 'noticeToSave']),
    ...mapState('users', { noticeer: 'userId' }),
    isSaved() {
      return !!this.addedNotice && !!this.addedNotice.id
    },
    validatedNoticeToSave() {
      return this.noticeToSave.name
    },
    displayContracts() {
      return this.noticeToSave.documentMode === 'pick'
    },
  },
  created() {
    this.loadNotice()
  },
  methods: {
    ...mapActions('notices', [
      'addNotice',
      'updateNotice',
      'uploadFile',
      'uploadReport',
      'addRecipients',
      'deleteRecipients',
      'addQuery',
      'deleteQuery',
    ]),
    ...mapMutations('notices', ['setAddedNotice', 'setNoticeToSave']),
    async loadNotice() {
      try {
        if (this.notice && this.notice.id) {
          this.setNotice(this.notice)
        } else if (this.$route.params.id) {
          const noticeId = parseInt(this.$route.params.id)
          this.setNotice(await fetchNoticeById(noticeId))
          this.setAddedNotice(this.noticeToSave)
        } else {
          this.setNotice()
          this.setAddedNotice({})
        }
        this.isLoading = false
      } catch (error) {
        errorOnDataLoad({ context: this, error })
      }
    },
    async setNotice(notice) {
      let noticeToSave
      if (notice && notice.id) {
        noticeToSave = {
          id: notice.id,
          name: notice.name,
          description: notice.description,
          notifyBy: notice.notifyBy,
          confirmationRequested: notice.confirmationRequested,
          noticeType: notice.noticeType,
          documentMode: notice.documentMode,
          date: formatDateForDatepicker(notice.date),
          hour: formatHourForHourpicker(notice.date),
        }
      } else {
        noticeToSave = {
          name: '',
          description: '',
          notifyBy: [],
          confirmationRequested: false,
          noticeType: 'communication',
          documentMode: 'unique',
          date: formatDateForDatepicker(),
          hour: getCurrentHour(),
        }
      }
      this.setNoticeToSave(noticeToSave)
    },
    async onSave() {
      this.isLoading = true
      if (!this.validatedNoticeToSave) {
        this.isLoading = false
        errorOnDataSave({ context: this, error: 'Campos incompletos' })
        return false
      }
      const result = this.addedNotice.id
        ? await this.update()
        : await this.add()
      this.isLoading = false
      return result
    },
    async add() {
      try {
        await this.addNotice()
        await this.upload()
        return true
      } catch (error) {
        errorOnDataSave({ context: this, error })
        return false
      }
    },
    async update() {
      try {
        await this.updateNotice()
        await this.upload()
        return true
      } catch (error) {
        errorOnDataSave({ context: this, error })
        return false
      }
    },
    async remove() {
      try {
        await deleteNotice(this.addedNotice.id)
        this.close()
      } catch (error) {
        errorOnDataSave({ context: this, error })
      }
    },
    async upload() {
      this.isLoading = true
      await this.uploadFile(this.addedNotice.id)
      await this.uploadReport(this.addedNotice.id)
      this.isLoading = false
    },
    async onUsersAdd(userIds) {
      this.isLoading = true
      await this.addRecipients({
        recipients: userIds,
        contractOps: this.displayContracts,
      })
      this.notifyRefresh()
    },
    async onQueryAdd(query) {
      this.isLoading = true
      await this.addQuery(query)
      this.notifyRefresh()
    },
    async onUsersRemove(userIds) {
      this.isLoading = true
      await this.deleteRecipients(userIds)
      this.notifyRefresh()
    },
    async onQueryRemove(query) {
      this.isLoading = true
      await this.deleteQuery(query)
      this.notifyRefresh()
    },
    async onAvailablePageChange(query) {
      this.isLoading = true
      this.available = await fetchAvailableRecipients(
        this.addedNotice.id,
        query
      )
      this.isLoading = false
    },
    async onSelectedPageChange(query) {
      this.isLoading = true
      const recipientResponse = await fetchSelectedRecipients(
        this.addedNotice.id,
        query
      )
      recipientResponse.recipients = recipientResponse.recipients.map(
        rec => rec.user
      )
      this.selected = recipientResponse
      this.isLoading = false
    },
    async onReviewPageChange(query) {
      this.isLoading = true
      const recipientResponse = await fetchSelectedRecipients(
        this.addedNotice.id,
        query
      )
      recipientResponse.recipients = recipientResponse.recipients.map(
        rec => rec.user
      )
      this.selectedReview = recipientResponse
      this.isLoading = false
    },
    async onSendNotification() {
      this.isLoading = true
      await publish(this.addedNotice.id)
      this.isLoading = false
      this.close()
    },
    async onOpenContract(userId) {
      await openContract(userId, this.addedNotice.id)
    },
    notifyRefresh() {
      this.refresh = true
    },
    async checkChanges(prev, to) {
      if (prev === 0 && to === 1) {
        this.refresh = true
      } else if (prev === 1 && to === 2) {
        this.refreshReview = true
      }
    },
    close() {
      this.$router.push({
        path: '/admin/comunicaciones',
      })
    },
  },
}
</script>

<style scoped></style>
