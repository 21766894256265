import { httpApi } from './http/httpApi'

export const fetchPartners = async () => {
  try {
    const response = await httpApi.get(
      `/users?roles=${JSON.stringify(['partner'])}`
    )
    if (response.status === 200) {
      return response.data
    }
  } catch (err) {
    return null
  }
}

export const fetchAllUsers = async query => {
  try {
    const url = `/users${userQueryBuilder(query)}`
    const response = await httpApi.get(url)
    if (response.status === 200) {
      return response.data
    } else {
      return {}
    }
  } catch (err) {
    return {}
  }
}

export const countUsers = async () => {
  try {
    const response = await httpApi.get('/users/count')
    if (response.status === 200) {
      return response.data
    }
    return 0
  } catch (err) {
    return 0
  }
}

export const userQueryBuilder = ({
  page,
  quantity,
  search,
  sortKey,
  sortOrder,
  roles,
  dates,
  contractOps,
  multipleRoles,
}) => {
  let url = ''
  if (page > 0 && quantity) {
    url += `?take=${quantity}&skip=${quantity * (page - 1)}`
  }
  if (search) {
    url += `&search=${search}`
  }
  if (sortKey && sortOrder) {
    url += `&sortKey=${sortKey}&order=${
      sortOrder === 'ascending' ? 'ASC' : 'DESC'
    }`
  }
  if (roles && roles.length > 0) {
    url += `&roles=${JSON.stringify(roles)}&multipleRoles=${multipleRoles}`
  }
  if (dates && dates[0] && dates[1]) {
    url += `&dates=${JSON.stringify(dates)}`
  }
  if (contractOps) {
    url += '&contractOps=true'
  }
  if (url.charAt(0) === '&') url = `?${url.slice(1)}`
  return url
}
