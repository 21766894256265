export const cleanSession = () => {
  sessionStorage.clear()
}

const save = (key, value) => {
  if (typeof key !== 'string') {
    throw new Error('Key must be string')
  }
  sessionStorage.setItem(key, JSON.stringify(value))
}

const load = key => {
  if (typeof key !== 'string') {
    throw new Error('Key must be string')
  }
  let loadedValue
  try {
    loadedValue = JSON.parse(sessionStorage.getItem(key))
  } catch (error) {
    cleanSession()
  }
  return loadedValue
}

export const saveAuthData = ({ token, userId, name, roles }) => {
  save('token', token)
  save('userId', userId)
  save('roles', roles)
  save('name', name)
}

export const loadToken = () => {
  return load('token')
}
export const loadUserId = () => {
  return load('userId')
}
export const loadUserName = () => {
  return load('name')
}
export const loadUserRoles = () => {
  const roles = load('roles')
  return roles || []
}
export const isUserAuthenticated = () => {
  return !!load('token')
}
