import { canAccess } from '@/guard/auth.guard'
import {
  cleanSession,
  isUserAuthenticated,
  loadUserId,
  saveAuthData,
} from '@/persistence/sessionStorage'
import store from '@/store/store'
import Vue from 'vue'
import Router from 'vue-router'
import { routes } from './routes'

Vue.use(Router)

const router = new Router({
  routes,
  linkActiveClass: 'nav-item active',
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  /*
  if (to.name !== 'login' && !isUserAuthenticated()) {
    store.dispatch('router/setRedirectionAfterLogin', to.path, { root: true })
    next({ name: 'login', replace: true })
  } else if (to.name === 'login' && isUserAuthenticated()) {
    next({ path: '/', replace: true })
  } else {
    if (canAccess(to)) {
      next()
    } else {
      next({ name: 'unauthorized', replace: true })
    }
  }
  */
  const routerParams = { to, from, next }
  const action = shouldGoTo(routerParams)
  routerConditions[action](routerParams)
})

export default router

const shouldLogin = ({ to }) => {
  return !pageIsLogin(to) && !isUserAuthenticated() && !pageTokenIsPresent(to)
}

const shouldLoginAfterTokenPage = ({ to }) => {
  return (
    !pageIsLogin(to) &&
    isUserAuthenticated() &&
    !loadUserId() &&
    !pageTokenIsPresent(to)
  )
}

const canAccessTokenPageNotAuthenticated = ({ to }) => {
  return !pageIsLogin(to) && !isUserAuthenticated() && pageTokenIsPresent(to)
}

const canAccessTokenPageAuthenticated = ({ to }) => {
  return !pageIsLogin(to) && isUserAuthenticated() && pageTokenIsPresent(to)
}

const canAccessNormalPage = ({ to }) => {
  return (
    !pageIsLogin(to) &&
    isUserAuthenticated() &&
    !pageTokenIsPresent(to) &&
    canAccess(to)
  )
}

const cannotAccessNormalPage = ({ to }) => {
  return (
    !pageIsLogin(to) &&
    isUserAuthenticated() &&
    !pageTokenIsPresent(to) &&
    !canAccess(to)
  )
}

const isAlreadyLoggedIn = ({ to }) => {
  return pageIsLogin(to) && isUserAuthenticated() && !pageTokenIsPresent(to)
}

const goingToLogIn = ({ to }) => {
  return pageIsLogin(to) && !isUserAuthenticated()
}

const shouldGoTo = ({ to, from, next }) => {
  if (shouldLogin({ to, from, next })) return 'SHOULD_LOGIN'
  if (shouldLoginAfterTokenPage({ to, from, next }))
    return 'SHOULD_LOGIN_FROM_TOKEN_PAGE'
  if (canAccessTokenPageNotAuthenticated({ to, from, next }))
    return 'CAN_ACCESS_TOKEN_PAGE_NOT_AUTHENTICATED'
  if (canAccessTokenPageAuthenticated({ to, from, next }))
    return 'CAN_ACCESS_TOKEN_PAGE_AUTHENTICATED'
  if (isAlreadyLoggedIn({ to, from, next })) return 'ALREADY_LOGGED_IN'
  if (canAccessNormalPage({ to, from, next })) return 'CAN_ACCESS_NORMAL_PAGE'
  if (cannotAccessNormalPage({ to, from, next })) return 'CANNOT_ACCESS'
  if (goingToLogIn({ to, from, next })) return 'GOING_TO_LOGIN'
  return 'DEFAULT'
}

const routerConditions = {
  SHOULD_LOGIN: ({ to, next }) => {
    store.dispatch('router/setRedirectionAfterLogin', to.path, { root: true })
    next({ name: 'login', replace: true })
  },
  SHOULD_LOGIN_FROM_TOKEN_PAGE: ({ to, next }) => {
    store.dispatch('router/setRedirectionAfterLogin', to.path, { root: true })
    cleanSession()
    next({ name: 'login', replace: true })
  },
  CAN_ACCESS_TOKEN_PAGE_NOT_AUTHENTICATED: ({ to, next }) => {
    const { token } = to.query
    const userId = null
    const name = null
    const roles = null
    saveAuthData({ token, userId, name, roles })
    next()
  },
  CAN_ACCESS_TOKEN_PAGE_AUTHENTICATED: ({ next }) => {
    next()
  },
  CANNOT_ACCESS: ({ next }) => {
    next({ path: '/sin-permiso', replace: true })
  },
  CAN_ACCESS_NORMAL_PAGE: ({ next }) => {
    next()
  },
  GOING_TO_LOGIN: ({ next }) => {
    next()
  },
  ALREADY_LOGGED_IN: ({ next }) => {
    next({ path: '/', replace: true })
  },
}

const pageTokenIsPresent = to => {
  return to.query && to.query.token
}

const pageIsLogin = to => {
  return to.name === 'login'
}
