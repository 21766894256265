<template>
  <div>Cerrando sesion</div>
</template>
<script>
import { cleanSession } from '@/persistence/sessionStorage'
export default {
  name: 'SignOut',
  mounted() {
    cleanSession()
    this.$router.replace({ name: 'login' })
    // @TODO Clean store
    /*
    let state = this.$store.state
    let newState = {}

    Object.keys(state).forEach(key => {
      newState[key] = null
    })

    this.$store.replaceState(newState) */
  },
}
</script>
