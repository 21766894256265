<template>
  <b-container fluid>
    <b-row align-v="center" align-h="center">
      <b-col md="8" xs="12">
        <b-card class="mt-5">
          <center>
            <h2>Página no encontrada</h2>

            <b-img fluid thumbnail class="mb-3" src="/img/not-found.jpg" />
            <b-button block size="lg" variant="primary" to="/">
              Volver a inicio
            </b-button>
          </center>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {}
</script>
