<template>
  <nav class="navbar navbar-expand-lg">
    <b-container fluid>
      <b-col cols="2" href="#/">
        <b-button variant="outline-secondary" size="lg" to="/">
          <i class="fas fa-home" />
        </b-button>
      </b-col>
      <b-col cols="8">
        <b-row style="justify-content: center">
          <p class="navbar-text navbar-brand pl-3">
            {{ routeName }}
          </p>
        </b-row>
      </b-col>
      <b-col cols="2">
        <button
          type="button"
          class="navbar-toggler navbar-toggler-right"
          :class="{ toggled: $sidebar.showSidebar }"
          aria-controls="navigation-index"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="toggleSidebar"
        >
          <span class="navbar-toggler-bar burger-lines" />
          <span class="navbar-toggler-bar burger-lines" />
          <span class="navbar-toggler-bar burger-lines" />
        </button>
        <div class="collapse navbar-collapse justify-content-end">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item" />
            <li class="nav-item">
              <b-button variant="outline-secondary" @click="logout">
                Cerrar sesión
              </b-button>
            </li>
          </ul>
        </div>
      </b-col>
    </b-container>
  </nav>
</template>
<script>
import { cleanSession } from '@/persistence/sessionStorage'
export default {
  data() {
    return {
      activeNotifications: false,
    }
  },
  computed: {
    routeName() {
      const { name } = this.$route
      return name.toUpperCase()
    },
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications
    },
    closeDropDown() {
      this.activeNotifications = false
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false)
    },
    logout() {
      cleanSession()
      this.$router.replace({ name: 'login' })
    },
  },
}
</script>
<style scoped>
.navbar-brand {
  margin-top: 0;
  margin-bottom: 0;
}

.navbar-brand .btn {
  margin-top: 0;
  margin-bottom: 0;
}
</style>
