<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <b-row class="pb-3 pl-3">
          <b-form-checkbox-group
            v-model="filters.roles"
            :options="roleFilter"
          />
        </b-row>
        <b-row class="pb-3 pl-3">
          <b-form-radio-group v-model="filters.seen" :options="seenFilter" />
          <b-button size="sm" variant="primary" @click="filters.seen = null">
            <i class="fas fa-redo" />
          </b-button>
        </b-row>
        <b-row v-if="confirmationRequested" class="pb-3 pl-3">
          <b-form-radio-group
            v-model="filters.accepted"
            :options="acceptedFilter"
          />
          <b-button
            size="sm"
            variant="primary"
            @click="filters.accepted = null"
          >
            <i class="fas fa-redo" />
          </b-button>
        </b-row>
        <b-row class="pb-3 pl-3 pr-3">
          <b-input-group align-self="center">
            <b-input-group-prepend is-text>
              <i class="fas fa-search" />
            </b-input-group-prepend>
            <b-input
              v-model="filters.search"
              placeholder="Buscar"
              class="input-with-select"
              clearable
            />
          </b-input-group>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <p>
          <b
            >La busqueda ha encontrado
            {{ filteredRecipients.length }} destinatario{{
              filteredRecipients.length !== 1 ? 's' : ''
            }}</b
          >
        </p>
        <b-table
          sticky-header="600px"
          :items="filteredRecipients"
          :fields="tableFields"
          :per-page="perPage"
          :current-page="currentPage"
          striped
          bordered
        >
          <template #cell(user.roles)="data">
            <div>
              <b-badge
                v-for="role in data.item.user.roles"
                :key="role"
                class="mr-3"
              >
                {{ roleNames[role] }}
              </b-badge>
            </div>
          </template>
          <template #cell(seen)="data">
            <b-badge :variant="data.item.seen ? 'success' : 'danger'">
              {{ data.item.seen ? 'Vista' : 'No vista' }}
            </b-badge>
          </template>
          <template #cell(accepted)="data">
            <b-badge
              :variant="
                data.item.confirmed
                  ? data.item.accepted
                    ? 'success'
                    : 'danger'
                  : ''
              "
            >
              {{
                data.item.confirmed
                  ? data.item.accepted
                    ? 'Aceptada'
                    : 'No aceptada'
                  : 'Sin confirmar'
              }}
            </b-badge>
          </template>
          <template #cell(id)="data">
            <b-button
              block
              variant="primary"
              size="sm"
              @click="openPdf(data.item.user.id, data.item.noticeId)"
            >
              Ver contrato
            </b-button>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          align="center"
          first-number
          last-number
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import roleNames from '@/pages/users/roleNames.json'
import { openContract } from '@/services/filesService.js'
export default {
  name: 'SenderRecipientTable',
  props: {
    recipients: {
      type: Array,
      default() {
        return []
      },
    },
    confirmationRequested: { type: Boolean, default: false },
    contracts: { type: Boolean, default: false },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      filters: {
        search: '',
        seen: null,
        accepted: null,
        roles: [],
      },
      seenFilter: [
        { text: 'Vista', value: true },
        { text: 'No vista', value: false },
      ],
      acceptedFilter: [
        { text: 'Aceptada', value: 'accepted' },
        { text: 'No aceptada', value: 'rejected' },
        { text: 'Sin confirmar', value: 'nc' },
      ],
      roleNames,
      roleFilter: [
        { text: 'Socios', value: 'partner' },
        { text: 'Propietarios', value: 'owner' },
        { text: 'Empleados', value: 'employee' },
      ],
      baseFields: [
        { key: 'user.code', label: 'Código', sortable: true },
        { key: 'user.name', label: 'Nombre', sortable: true },
        { key: 'roles', label: 'Roles' },
        { key: 'seen', label: 'Vista', sortable: true },
      ],
    }
  },
  computed: {
    rows() {
      return this.filteredRecipients.length
    },
    tableFields() {
      const fields = [...this.baseFields]
      if (this.confirmationRequested) {
        fields.push({ key: 'accepted', label: 'Aceptada', sortable: true })
      }
      if (
        this.recipients &&
        this.recipients.some(
          rec => rec.user.partner && rec.user.partner.partnerNumber
        )
      ) {
        fields.push({
          key: 'user.partner.partnerNumber',
          label: 'Nº Socio',
          sortable: true,
        })
      }
      if (this.contracts) {
        fields.push({ key: 'id', label: 'Contratos' })
      }
      return fields
    },
    filteredRecipients() {
      if (this.recipients) {
        try {
          return this.recipients.filter(
            rec =>
              (rec.user.code
                .toString()
                .toLowerCase()
                .includes(this.filters.search.toLowerCase()) ||
                rec.user.name
                  .toLowerCase()
                  .includes(this.filters.search.toLowerCase())) &&
              (this.filters.seen === null || rec.seen === this.filters.seen) &&
              (this.filters.accepted === null ||
                (this.filters.accepted === 'accepted' &&
                  rec.confirmed &&
                  rec.accepted) ||
                (this.filters.accepted === 'rejected' &&
                  rec.confirmed &&
                  !rec.accepted) ||
                (this.filters.accepted === 'nc' && !rec.confirmed)) &&
              (this.filters.roles.length === 0 ||
                this.filters.roles.every(role => rec.user.roles.includes(role)))
          )
        } catch (err) {
          console.log(err)
          return []
        }
      }
      return []
    },
  },
  methods: {
    async openPdf(userId, noticeId) {
      await openContract(userId, noticeId)
    },
  },
}
</script>
