import {
  getActiveCampain,
  fetchTreatments,
  fetchTreatmentsRunner,
  fetchCampains,
  fetchPlots,
  fetchPlotsRunner,
  fetchVarieties,
  fetchPlagues,
  fetchProducts,
  sendNewTreatment,
  endNewTreatment,
  deleteTreatment,
} from '@/services/treatmentsService'
import { formatDateISO } from '@/services/time'

export const treatmentsModule = {
  namespaced: true,
  state: {
    runnercode: '',
    ownercode: '',
    campain: '',
    selcampain: '',
    campains: [],
    treatments: [],
    plots: [],
    plagues: [],
    varieties: [],
    products: [],
    treatmentCreated: {
      plot: '',
      variety: '',
      date: '',
      plague: '',
      product: '',
    },
    treatmentsCreatedList: [],
    idtreatmentsCreatedList: 0,
  },
  mutations: {
    setOwnerRole(state, owner) {
      state.ownercode = owner
      state.runnercode = ''
    },
    setRunnerRole(state, runner) {
      state.ownercode = ''
      state.runnercode = runner
    },
    setTreatments(state, treatments) {
      state.treatments = [...treatments]
    },
    setPlots(state, plots) {
      state.plots = plots
    },
    setPlot(state, plot) {
      state.ownercode = plot.propietario
      state.treatmentCreated.plot = plot
      state.treatmentCreated.variety = ''
      state.treatmentCreated.date = ''
      state.treatmentCreated.plague = ''
      state.treatmentCreated.product = ''
    },
    setVarieties(state, varieties) {
      state.varieties = varieties
    },
    setVariety(state, variety) {
      state.treatmentCreated.variety = variety
      state.treatmentCreated.date = ''
      state.treatmentCreated.plague = ''
      state.treatmentCreated.product = ''
    },
    setPlagues(state, plagues) {
      state.plagues = plagues
    },
    setPlague(state, plague) {
      state.treatmentCreated.plague = plague
      state.treatmentCreated.date = ''
      state.treatmentCreated.product = ''
    },
    setProducts(state, products) {
      state.products = products
    },
    setProduct(state, product) {
      state.treatmentCreated.product = product
    },
    setActiveCampain(state, campain) {
      state.campain = campain
      state.selcampain = campain
    },
    setSelectedCampain(state, campain) {
      state.selcampain = campain
    },
    setCampainsList(state, campains) {
      state.campains = campains
    },
    setPPSDate(state, date) {
      state.treatmentCreated.date = date
    },
    clearVariety(state) {
      state.treatmentCreated.variety = ''
    },
    saveTreatmentAplication(state) {
      let obj = {}
      //obj.pPPS = state.treatmentCreated.product.descripcion
      obj.pPPS = state.treatmentCreated.product.registro
      obj.product = state.treatmentCreated.product.comercial
      obj.plague = state.treatmentCreated.plague.descripcion
      obj.pDate = state.treatmentCreated.date
      obj.pPaleta = state.treatmentCreated.plot.paleta
      obj.pVariedad = state.treatmentCreated.variety.variedad
      obj.id = state.idtreatmentsCreatedList
      // console.log(obj)
      state.treatmentsCreatedList.push(obj)
      // state.treatmentCreated.plot = ''
      // state.treatmentCreated.variety = ''
      state.treatmentCreated.date = ''
      state.treatmentCreated.plague = ''
      state.treatmentCreated.product = ''
      state.idtreatmentsCreatedList += 1
    },
    resetTreatmentCreate(state) {
      state.treatmentsCreatedList.splice(0, state.treatmentsCreatedList.length)
      state.treatmentCreated.plot = ''
      state.treatmentCreated.variety = ''
      state.treatmentCreated.date = ''
      state.treatmentCreated.plague = ''
      state.treatmentCreated.product = ''
    },
    resetTreatmentList(state) {
      state.treatmentsCreatedList.splice(0, state.treatmentsCreatedList.length)
    },
    deletePPSTreatmentList(state, pps) {
      state.treatmentsCreatedList.splice(
        state.treatmentsCreatedList.indexOf(pps),
        1
      )
    },
    setTreatmentToDelete(state, treatment) {
      state.treatmentToDelete = treatment
    },
  },

  actions: {
    fetchActiveCampain: async ({ commit }) => {
      const response = await getActiveCampain()
      console.log(response[0].CampanyaActiva.replace('/', '-'))
      commit('setActiveCampain', response[0].CampanyaActiva.replace('/', '-'))
    },

    fetchCampains: async ({ commit }) => {
      const campains = await fetchCampains()
      if (campains) {
        commit('setCampainsList', campains)
      }
    },
    fetchTreatments: async ({ commit, state }) => {
      let treatmnents = ''
      console.log
      if (state.runnercode == '')
        treatmnents = await fetchTreatments(
          state.ownercode + '/' + state.selcampain
        )
      else
        treatmnents = await fetchTreatmentsRunner(
          state.runnercode + '/' + state.selcampain
        )
      if (treatmnents) {
        commit('setTreatments', treatmnents)
      }
    },
    fetchPlots: async ({ commit, state }) => {
      let plots = ''
      if (state.runnercode == '')
        plots = await fetchPlots(state.ownercode + '/' + state.selcampain)
      else
        plots = await fetchPlotsRunner(
          state.runnercode + '/' + state.selcampain
        )
      if (plots) {
        commit('setPlots', plots)
      }
    },
    fetchVarieties: async ({ commit, state }) => {
      const varieties = await fetchVarieties(state.treatmentCreated.plot.paleta)
      if (varieties) {
        commit('setVarieties', varieties)
      }
    },
    fetchPlagues: async ({ commit }) => {
      const plague = await fetchPlagues()
      if (plague) {
        commit('setPlagues', plague)
      }
    },
    fetchProducts: async ({ commit, state }) => {
      const products = await fetchProducts(state.treatmentCreated.plague.codigo)
      if (products) {
        commit('setProducts', products)
      }
    },
    savePPS: async ({ state }) => {
      let obj = {}
      // export class TreatmentPPS {
      //   pps: string;
      //   date: Date;
      //   plot: string;
      //   variety: string;
      // }
      obj.plot = state.treatmentCreated.plot.paleta
      obj.variety = state.treatmentCreated.variety.variedad
      const listPPS = state.treatmentsCreatedList
      listPPS.forEach(pps => {
        obj.date = pps.pDate
        obj.pps = pps.pPPS
        sendNewTreatment(obj)
      })
    },
    saveTreatment: async ({ state }) => {
      let obj = {}
      // export class TreatmentHeader {
      //   date: Date;
      //   plot: string;
      //   variety: string;
      // }
      obj.date = formatDateISO()
      obj.plot = state.treatmentCreated.plot.paleta
      obj.variety = state.treatmentCreated.variety.variedad
      endNewTreatment(obj)
    },

    deleteTreatment: async ({ commit, state }, treatment) => {
      let obj = {}
      obj.plot = treatment.paleta
      obj.nlin = treatment.nlineacontrato
      await deleteTreatment(obj)
      commit(
        'setTreatments',
        state.treatments.filter(
          el => el.nlineacontrato !== treatment.nlineacontrato
        )
      )
    },
  },
  getters: {
    findTreatmentsById(state) {
      return id => {
        const treatment = state.treatment.find(el => el.id === id)
        return treatment
      }
    },
  },
}
