<template>
  <b-col>
    <button-menu :items="menu" />
  </b-col>
</template>

<script>
import Menu from '@/components/menus/Menu'
export default {
  name: 'MainMenu',
  components: {
    'button-menu': Menu,
  },
  data: function () {
    return {
      menu: [
        {
          title: 'Documentos',
          buttonText: 'Ver documentos',
          path: '/menu-documentos',
          icon: 'file-alt',
          meta: {
            roles: ['adm720', 'employee', 'partner', 'owner'],
          },
        },
        {
          title: 'Comunicaciones',
          buttonText: 'Ver comunicaciones',
          path: '/comunicaciones',
          icon: 'paper-plane',
          meta: {
            roles: ['adm720', 'employee', 'partner', 'owner'],
          },
        },
        {
          title: 'Convocatorias',
          buttonText: 'Ver convocatorias',
          path: '/convocatorias',
          icon: 'bell',
          meta: {
            roles: ['adm720', 'employee', 'partner', 'owner'],
          },
        },
         {
          title: 'Tratamientos',
          buttonText: 'Ver tratamientos',
          path: '/tratamientos',
          icon: 'bong',
          meta: {
            roles: ['adm720', 'owner','runner'],
          },
        },
      ],
    }
  },
}
</script>
