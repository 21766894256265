import Vue from 'vue'
import Vuex from 'vuex'
import { authModule } from '@/store/modules/auth'
import { payrollsModule } from '@/store/modules/payrolls'
import { usersModule } from '@/store/modules/users'
import { withholdingsModule } from '@/store/modules/withholdings'
import { documentationModule } from './modules/documentation'
import { noticesModule } from './modules/notices'
import { treatmentsModule } from './modules/treatments'
import { routerModule } from './modules/router'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    users: usersModule,
    notices: noticesModule,
    auth: authModule,
    withholdings: withholdingsModule,
    payrolls: payrollsModule,
    documentation: documentationModule,
    treatments: treatmentsModule,
    router: routerModule,
  },
})
