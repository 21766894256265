<template>
  <b-container fluid>
    <control-list-bar @searchChange="newSearch => (searchedText = newSearch)" />
    <template v-if="contracts.length">
      <b-row v-for="contract in filteredContracts" :key="contract.id">
        <b-col align-self="center">
          <contract-card :contract="contract" />
        </b-col>
      </b-row>
    </template>
    <b-row v-else>
      <b-col cols="12" align-self="center">
        <p class="text-center font-weight-bold bg-light text-uppercase">
          No hay contratos disponibles
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ContractCard from '@/components/cards/ContractCard'
import names from '@/config/names'
import tagsColors from '@/pages/contract/tagColors.json'
import { mapActions, mapState } from 'vuex'
import ControlListBar from '@/components/search/ControlListBar'
import { errorOnDataLoad } from '@/services/messageService'

export default {
  name: 'AttendeeContractList',
  components: {
    ContractCard,
    ControlListBar,
  },
  data() {
    return {
      sortBy: 'date',
      searchedText: '',
      isLoading: true,
      names,
    }
  },
  computed: {
    ...mapState('notices', { contracts: 'contractRecipients' }),
    filteredContracts() {
      return this.contracts
        .filter(contract => {
          for (const property of Object.values(contract)) {
            if (
              typeof property === 'string' &&
              property.toLowerCase().includes(this.searchedText.toLowerCase())
            ) {
              return true
            }
          }
        })
        .sort((a, b) => new Date(b.date) - new Date(a.date))
    },
  },
  watch: {
    filteredContracts: {
      handler: function () {},
      deep: true,
    },
    contracts: {
      handler: function () {},
      deep: true,
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    ...mapActions('notices', ['fetchNoticeRecipients']),
    retrieveTableRowClassName({ row }) {
      if (row.status === 'pending') {
        return 'warning-row'
      }
      return ''
    },
    selectStatusColor(status) {
      return tagsColors[status]
    },
    async loadData() {
      try {
        await this.fetchNoticeRecipients('contract')
        this.isLoading = false
      } catch (error) {
        errorOnDataLoad({ context: this, error })
      }
    },
  },
}
</script>

<style scoped></style>
