<template>
  <b-container fluid>
    <control-list-bar
      @sortChange="newSortBy => (sortBy = newSortBy)"
      @searchChange="newSearch => (searchedText = newSearch)"
    /><template v-if="payrolls.length">
      <b-row v-for="payroll in filteredPayrolls" :key="payroll.id">
        <b-col align-self="center">
          <payroll-card :payroll="payroll" />
        </b-col>
      </b-row>
    </template>
    <b-row v-else>
      <b-col cols="12" align-self="center">
        <p class="text-center font-weight-bold bg-light text-uppercase">
          No hay nóminas disponibles aún
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import PayrollCard from '@/components/cards/PayrollCard'
import ControlListBar from '@/components/search/ControlListBar'
import { parseDate } from '@/services/time'
export default {
  name: 'PayrollList',
  components: {
    PayrollCard,
    ControlListBar,
  },
  data() {
    return {
      searchedText: '',
      file: '',
      sortBy: '',
    }
  },
  computed: {
    ...mapState('payrolls', ['payrolls']),
    filteredPayrolls() {
      return this.payrolls
        .filter(payroll => {
          for (const property of Object.values(payroll)) {
            if (
              typeof property === 'string' &&
              property.toLowerCase().includes(this.searchedText.toLowerCase())
            ) {
              return true
            }
          }
        })
        .map(el => ({
          ...el,
          payrollDate: parseDate(`${el.month} ${el.year}`, 'MMMM YYYY'),
        }))
        .sort((a, b) => new Date(b.payrollDate) - new Date(a.payrollDate))
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    ...mapActions('payrolls', ['fetchPayrolls']),
    async loadData() {
      await this.fetchPayrolls()
    },
  },
}
</script>

<style scoped></style>
