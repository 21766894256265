<template>
  <b-container fluid>
    <b-overlay :show="isLoading">
      <b-row align-h="end">
        <b-col cols="12">
          <b-button block variant="primary" to="/admin/comunicaciones/crear">
            Añadir
          </b-button>
        </b-col>
      </b-row>
      <control-list-bar
        @searchChange="newSearch => (searchedText = newSearch)"
      />
      <template v-if="notices.length">
        <b-row v-for="notice in filteredNotices" :key="notice.id">
          <b-col align-self="center">
            <notice-card :notice="notice" />
          </b-col>
        </b-row>
      </template>
      <b-row v-else>
        <b-col cols="12" align-self="center">
          <p class="text-center font-weight-bold bg-light text-uppercase">
            No hay comunicaciones disponibles
          </p>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>
<script>
import names from '@/config/names'
import { mapState, mapActions } from 'vuex'
import ControlListBar from '@/components/search/ControlListBar'
import NoticeCard from '@/components/cards/SenderNoticeCard'

export default {
  name: 'SenderNoticeList',
  components: {
    NoticeCard,
    ControlListBar,
  },
  data() {
    return {
      searchedText: '',
      names,
      isLoading: true,
    }
  },
  computed: {
    ...mapState('notices', ['notices']),
    filteredNotices() {
      const filteredNotices = this.notices
        .filter(notice => {
          for (const noticeProp in notice) {
            if (
              typeof notice[noticeProp] === 'string' &&
              notice[noticeProp]
                .toLowerCase()
                .includes(this.searchedText.toLowerCase())
            ) {
              return true
            }
          }
          return false
        })
        .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))
      return filteredNotices
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    ...mapActions('notices', ['fetchNotices']),
    loadData: async function () {
      await this.fetchNotices()
      this.isLoading = false
    },
  },
}
</script>

<style scoped></style>
