// Notifications plugin
//import Notifications from '@/components/NotificationPlugin'
// asset imports
import 'bootstrap/dist/css/bootstrap.css'
//import VTooltip from 'v-tooltip'
//import './assets/css/demo.css'
import './assets/sass/light-bootstrap-dashboard.scss'
// Sidebar on the right. Used as a local plugin in DashboardLayout.vue
import SideBar from './components/SidebarPlugin'

/**
 * This is the main Light Bootstrap Dashboard Vue plugin where dashboard related plugins are registerd.
 */
export default {
  install(Vue) {
    Vue.use(SideBar)
  },
}
