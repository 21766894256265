<template>
  <b-container fluid>
    <b-overlay :show="isLoading">
      <control-list-bar
        @searchChange="newSearch => (searchedText = newSearch)"
      />
      <div id="containlist">
        <template v-if="plots.length">
          <b-row v-for="plot in filteredPlots" :key="plot.id">
            <b-col align-self="center">
              <plot-card :plot="plot" />
            </b-col>
          </b-row>
        </template>
        <b-row v-else>
          <b-col cols="12" align-self="center">
            <p class="text-center font-weight-bold bg-light text-uppercase">
              No hay huertas disponibles
            </p>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </b-container>
</template>
<script>
import names from '@/config/names'
import { mapState, mapActions } from 'vuex'
import ControlListBar from '@/components/search/ControlListBar'
import PlotCard from '@/components/cards/TreatmentPlotCard'

export default {
  name: 'PlotList',
  components: {
    PlotCard,
    ControlListBar,
  },
  data() {
    return {
      searchedText: '',
      names,
      isLoading: true,
    }
  },
  computed: {
    ...mapState('treatments', ['treatmentCreated', 'plots']),
    filteredPlots() {
      const filteredPlots = this.plots.filter(plot => {
        for (const property in plot) {
          if (
            typeof plot[property] === 'string' &&
            plot[property]
              .toLowerCase()
              .includes(this.searchedText.toLowerCase())
          ) {
            return true
          }
        }
        return false
      })
      // .sort(
      //   (a, b) => new Date(b.fechaaplicacion) - new Date(a.fechaaplicacion)
      // )
      return filteredPlots
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    ...mapActions('treatments', ['fetchPlots']),
    loadData: async function () {
      // console.log('En loaddata')
      // await this.fetchPlots()
      this.isLoading = false
    },
  },
}
</script>

<style scoped>
div#containlist {
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}
@media screen and (max-width: 380px) {
  div#containlist {
    height: 450px;
  }
}
</style>
