import {
  fetchAllWithholdings,
  fetchUserWithholdings,
  fetchWithholdingById,
} from '@/services/withholdingsService'

export const withholdingsModule = {
  namespaced: true,
  state: {
    withholdings: [],
  },
  mutations: {
    setWithholdings(state, withholdings) {
      state.withholdings = withholdings
    },
  },
  actions: {
    fetchWithholdings: async ({ commit }) => {
      const withholdings = await fetchAllWithholdings()
      if (withholdings) {
        commit('setWithholdings', withholdings)
      }
    },
    fetchWithholdingById: async ({ commit }, withholdingId) => {
      const withholding = await fetchWithholdingById(withholdingId)
      if (withholding) {
        commit('setWithholding', withholding)
      }
    },
    fetchUserWithholdings: async ({ commit }) => {
      const withholdings = await fetchUserWithholdings()
      if (withholdings) {
        commit('setAllWithholdings', withholdings)
      }
    },
  },
  getters: {},
}
