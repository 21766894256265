<template>
  <b-container fluid>
    <b-overlay :show="isLoading">
      <br />
      <b-button block variant="info" to="/pps">
        Nueva aplicación de producto
      </b-button>

      <br />
      <template v-if="treatmentsCreatedList.length">
        <b-row v-for="pps in treatmentsCreatedList" :key="pps.id">
          <b-col align-self="center">
            <PPSCard :pps="pps" />
          </b-col>
        </b-row>
      </template>
      <b-row v-else>
        <b-col cols="12" align-self="center">
          <p class="text-center font-weight-bold bg-light text-uppercase">
            No hay aplicaciones realizadas
          </p>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>
<script>
import { mapState } from 'vuex'
import PPSCard from '@/components/cards/TreatmentPPSCard'
// import TreatmentCreatePPS from '@/pages/treatments/TreatmentCreatePPS.vue'

export default {
  name: 'TreatmentList',
  components: {
    PPSCard,
  },
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    ...mapState('treatments', ['treatmentsCreatedList']),
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData: async function () {
      this.isLoading = false
    },
  },
}
</script>

<style scoped></style>
