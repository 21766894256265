import { httpApi } from '@/services/http/httpApi'
import { parseDates } from './parser'
import { userQueryBuilder } from './usersService'

export const fetchNoticeRecipients = async type => {
  try {
    const response = await httpApi.get(`/recipients/notices?type=${type}`)
    if (response.status === 200) {
      return response.data.map(el => parseDates(el))
    }
    return []
  } catch (err) {
    return []
  }
}

export const fetchNoticeRecipientById = async (noticeId, type) => {
  try {
    const response = await httpApi.get(
      `/recipients/notices/${noticeId}${type ? '&type=' + type : ''}`
    )
    if (response.status === 200) {
      return parseDates(response.data)
    }
  } catch (err) {
    if (err.message === 'Request failed with status code 403') {
      throw err
    }
  }
}

export const seenNotice = async noticeId => {
  try {
    const response = await httpApi.put(
      `/recipients/notices/${noticeId}/seen`,
      {}
    )
    if (response.status === 200) {
      return response.data
    }
    return null
  } catch (err) {
    return null
  }
}

const confirmNotice = async (noticeId, accepted) => {
  try {
    const response = await httpApi.put(
      `/recipients/notices/${noticeId}/accepted`,
      { accepted }
    )
    if (response.status === 200) {
      return response.data
    }
  } catch (err) {
    return null
  }
}
export const acceptNotice = async noticeId => {
  return confirmNotice(noticeId, true)
}

export const rejectNotice = async noticeId => {
  return confirmNotice(noticeId, false)
}

export const fetchNotices = async () => {
  try {
    const response = await httpApi.get('/communications/notices')
    if (response.status === 200) {
      return response.data
    }
  } catch (err) {
    return null
  }
}
export const fetchNoticeById = async noticeId => {
  try {
    const response = await httpApi.get(`/communications/notices/${noticeId}`)
    if (response.status === 200) {
      return response.data
    }
  } catch (err) {
    return null
  }
}

export const fetchRecipients = async noticeId => {
  try {
    const response = await httpApi.get(
      `/recipients/notices/${noticeId}/recipients`
    )
    if (response.status === 200) {
      return response.data
    }
  } catch (err) {
    return null
  }
}

export const fetchAvailableRecipients = async (noticeId, query) => {
  try {
    const response = await httpApi.get(
      `/recipients/notices/${noticeId}/recipients/available${userQueryBuilder(
        query
      )}`
    )
    if (response.status === 200) {
      return response.data
    }
  } catch (err) {
    return { totalUsers: 0, users: [] }
  }
}

export const fetchSelectedRecipients = async (noticeId, query) => {
  try {
    const response = await httpApi.get(
      `/recipients/notices/${noticeId}/recipients${userQueryBuilder(query)}`
    )
    if (response.status === 200) {
      return response.data
    }
  } catch (err) {
    return null
  }
}

export const addRecipients = async (noticeId, recipientsIds, contractOps) => {
  try {
    const response = await httpApi.post(
      `/recipients/notices/${noticeId}/recipients${
        contractOps ? '?contractOps=true' : ''
      }`,
      recipientsIds
    )
    if (response.status === 201) {
      return response.data
    }
  } catch (err) {
    return []
  }
}

export const addRecipientsByQuery = async (noticeId, query) => {
  try {
    const response = await httpApi.post(
      `/recipients/notices/${noticeId}/recipients/search${userQueryBuilder(
        query
      )}`,
      query
    )
    if (response.status === 201) {
      return response.data
    }
  } catch (err) {
    return []
  }
}

export const deleteRecipients = async (noticeId, recipientsIds) => {
  try {
    const response = await httpApi.delete(
      `/recipients/notices/${noticeId}/recipients`,
      { data: recipientsIds }
    )
    if (response.status === 200) {
      return response.data
    }
  } catch (err) {
    return []
  }
}

export const deleteRecipientsByQuery = async (noticeId, query) => {
  try {
    const response = await httpApi.delete(
      `/recipients/notices/${noticeId}/recipients/search${userQueryBuilder(
        query
      )}`
    )
    if (response.status === 200) {
      return response.data
    }
  } catch (err) {
    return []
  }
}

export const addNotice = async notice => {
  try {
    const response = await httpApi.post('/communications/notices', notice)
    if (response.status === 201) {
      return response.data
    }
  } catch (err) {
    return null
  }
}
export const updateNotice = async (id, notice) => {
  try {
    const response = await httpApi.put(`/communications/notices/${id}`, notice)
    if (response.status === 200) {
      return response.data
    }
  } catch (err) {
    return null
  }
}

export const deleteNotice = async noticeId => {
  try {
    const response = await httpApi.delete(`/communications/notices/${noticeId}`)
    if (response.status === 200) {
      return true
    }
    return false
  } catch (err) {
    return false
  }
}

export const deleteNoticeFile = async noticeId => {
  try {
    const response = await httpApi.delete(
      `/communications/notices/${noticeId}/communication-file`
    )
    if (response.status === 200) {
      return true
    }
    return false
  } catch (err) {
    return false
  }
}

export const deleteReportFile = async noticeId => {
  try {
    const response = await httpApi.delete(
      `/communications/notices/${noticeId}/report-file`
    )
    if (response.status === 200) {
      return true
    }
    return false
  } catch (err) {
    return false
  }
}

export const publish = async noticeId => {
  try {
    const response = await httpApi.post(
      `/communications/notices/${noticeId}/publish`
    )
    if (response.status === 200) {
      return true
    }
    return false
  } catch (err) {
    return false
  }
}
