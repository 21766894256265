<template>
  <b-card no-body class="mb-3">
    <b-card-title class="mt-2">
      <b-container fluid>
        <b-row align-h="between">
          <b-col cols="12">
            <b-row>
              <h3 class="ml-3">
                {{ notice.name }}
              </h3>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-card-title>
    <b-card-sub-title>
      <b-container fluid>
        <b-row align-h="between">
          <b-col md="8" sm="12">
            <span>{{ notice.date | toDateTime }}</span>
          </b-col>
        </b-row>
      </b-container>
    </b-card-sub-title>
    <b-card-body>
      <b-row>
        <b-col md="8" xs="12">
          <p>{{ notice.description }}</p>

          <b-row class="ml-1">
            <h5>
              <b-badge class="mr-2">
                {{ noticeType }}
              </b-badge>
            </h5>
            <h5>
              <b-badge v-if="notice.published"> Publicada </b-badge>
            </h5>
          </b-row>
        </b-col>
        <b-col md="4" xs="12">
          <b-row>
            <b-button
              :variant="notice.hasCommunicationFile ? 'primary' : ''"
              :disabled="!notice.hasCommunicationFile"
              block
              size="lg"
              @click="openPdf('notice')"
            >
              {{
                notice.documentMode === 'unique'
                  ? notice.hasCommunicationFile
                    ? `Ver ${noticeType.toLowerCase()}`
                    : 'Sin documento'
                  : `Documento nominativo`
              }}
            </b-button>
            <b-button
              v-if="notice.noticeType === 'call'"
              block
              :disabled="!notice.hasReportFile"
              :variant="notice.hasReportFile ? 'primary' : ''"
              size="lg"
              @click="openPdf('report')"
            >
              {{ notice.hasReportFile ? `Ver acta` : 'Sin acta' }}
            </b-button>
          </b-row>
        </b-col>
        <b-col cols="12">
          <b-row align-h="start">
            <b-col cols="12">
              <h5>Resultados:</h5>
            </b-col>
            <b-col md="4" xs="12">
              <div>Destinatarios: {{ notice.recipients }}</div>
            </b-col>
            <template v-if="notice.recipients > 0">
              <b-col md="4" xs="12">
                <div>
                  Visto: {{ notice.seen }} ({{
                    ((notice.seen / notice.recipients) * 100).toFixed(1)
                  }}
                  %)
                </div>
                <div>
                  No Visto: {{ notice.recipients - notice.seen }} ({{
                    (
                      ((notice.recipients - notice.seen) / notice.recipients) *
                      100
                    ).toFixed(1)
                  }}
                  %)
                </div>
              </b-col>
              <template v-if="notice.confirmationRequested">
                <b-col md="2" xs="12">
                  <div>
                    Sin confirmar:
                    {{ notice.recipients - notice.accepted - notice.rejected }}
                    ({{
                      (
                        ((notice.recipients -
                          notice.accepted -
                          notice.rejected) /
                          notice.recipients) *
                        100
                      ).toFixed(1)
                    }}
                    %)
                  </div>
                </b-col>
                <b-col md="2" xs="12">
                  <div>
                    Aceptado: {{ notice.accepted }} ({{
                      ((notice.accepted / notice.recipients) * 100).toFixed(1)
                    }}
                    %)
                  </div>
                  <div>
                    No aceptado: {{ notice.rejected }} ({{
                      ((notice.rejected / notice.recipients) * 100).toFixed(1)
                    }}
                    %)
                  </div>
                </b-col>
              </template>
            </template>
          </b-row>
        </b-col>
      </b-row>
    </b-card-body>
    <b-card-footer>
      <b-row align-h="end">
        <b-col md="4" xs="12">
          <div class="float-right">
            <b-button
              class="mr-3"
              variant="outline-primary"
              :to="`/admin/comunicaciones/${notice.id}`"
            >
              Ver detalles
            </b-button>

            <b-button
              variant="warning"
              :to="`/admin/comunicaciones/${notice.id}/editar`"
            >
              Editar
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-footer>
  </b-card>
</template>
<script>
import { mapActions } from 'vuex'
import { openFile } from '@/services/filesService.js'
import { errorOnDataLoad } from '@/services/messageService'

export default {
  name: 'NoticeCard',
  props: {
    notice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      pdfUrl: '',
      types: {
        communication: 'Comunicación',
        call: 'Convocatoria',
        contract: 'Contrato',
      },
    }
  },
  computed: {
    noticeType() {
      return `${this.types[this.notice.noticeType]}` || ''
    },
  },
  methods: {
    ...mapActions('notices', ['acceptNotice', 'rejectNotice', 'seenNotice']),
    async openPdf(entity) {
      try {
        const fileProperties = {
          entity,
          entityID: this.notice.id,
          name: this.notice.name,
        }
        await openFile(fileProperties)
      } catch (error) {
        errorOnDataLoad({ context: this, error })
      }
    },
  },
}
</script>
<style>
.card-footer-notes {
  margin-bottom: 0;
  margin-top: 0.25em;
}
</style>
