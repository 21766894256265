<template>
  <b-container fluid>
    <b-overlay :show="isLoading">
      <b-row align-v="end" class="mt-3 mb-3">
        <b-col cols="8">
          <h3>
            {{
              treatmentCreated.variety.variedad
                ? treatmentCreated.plot.paleta +
                  ' : ' +
                  treatmentCreated.variety.variedad
                : treatmentCreated.plot.paleta
                ? treatmentCreated.plot.paleta
                : 'Seleccione '
            }}
          </h3>
          <h4>
            {{
              treatmentCreated.plot.paleta
                ? treatmentCreated.plot.termino +
                  ' ( ' +
                  treatmentCreated.plot.partida +
                  ' )'
                : ' .'
            }}
          </h4>
        </b-col>
        <b-col cols="4">
          <b-button-group>
            <b-button
              size="lg"
              :variant="tabIndex == 0 ? 'info' : 'primary'"
              @click="clickBefore()"
            >
              <i
                :class="
                  tabIndex == 0
                    ? 'fa fa-window-close'
                    : 'fas fa-arrow-circle-left'
                "
              ></i>
            </b-button>
            <b-button
              size="lg"
              :variant="tabIndex == 2 ? 'secondary' : 'primary'"
              @click="clickAfter()"
            >
              <i class="fas fa-arrow-circle-right"></i>
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col>
          <!-- <b-button-toolbar justify> -->
          <b-button-group>
            <b-button
              squared
              :variant="tabIndex == 0 ? 'secondary' : 'outline-primary'"
              @click="clickTab(0)"
              >PLAGA</b-button
            >
            <b-button
              squared
              :variant="tabIndex == 1 ? 'secondary' : 'outline-primary'"
              @click="clickTab(1)"
              >PRODUCTO</b-button
            >
            <b-button
              squared
              :variant="tabIndex == 2 ? 'secondary' : 'outline-primary'"
              :disabled="treatmentCreated.product != '' ? false : true"
              @click="clickTab(2)"
              >FECHA</b-button
            >
          </b-button-group>
          <!-- </b-button-toolbar> -->
        </b-col>
      </b-row>
      <template v-if="tabIndex == 0">
        <PlagueList />
      </template>
      <template v-if="tabIndex == 1">
        <p>{{ treatmentCreated.plague.descripcion }}</p>
        <ProductList />
      </template>
      <template v-if="tabIndex == 2">
        <b-row>
          <b-col md="6" xs="12">
            <div>
              <h4>{{ treatmentCreated.plague.descripcion }}</h4>
              <h3>
                {{ treatmentCreated.product.descripcion }}
              </h3>

              <br />
            </div>
          </b-col>
          <b-col md="6" xs="12">
            <b-form-group>
              <b-form-datepicker
                v-model="datepps"
                label-help=""
                size="sm"
                label-no-date-selected="Fecha de aplicación del producto"
                start-weekday="1"
                @input="onChangeDate()"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <template v-if="datenow">
          <br />
          <b-button block variant="primary" @click="onSavePPS">
            CONFIRMAR APLICACIÓN
          </b-button>
          <br />
        </template>
      </template>
      <!-- <div>Tab: {{ tabIndex }}</div> -->
      <br />
    </b-overlay>
  </b-container>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import moment from 'moment'
// import { errorOnDataSave, errorOnDataLoad } from '@/services/messageService'
// import { formatDateForDatepicker, getCurrentHour } from '@/services/time'
// import {
//   fetchTreatmentById,
//   deleteTreatment,
// } from '@/services/treatmentsService'
import PlagueList from '@/pages/treatments/TreatmentPlagueList.vue'
import ProductList from '@/pages/treatments/TreatmentProductList.vue'
// import { getCurrentDateDate } from '@/services/time'

export default {
  name: 'TreatmentCreatePPS',
  components: {
    PlagueList,
    ProductList,
  },
  props: {
    treatment: {
      type: Object,
      default() {
        return { id: null }
      },
    },
  },
  data() {
    return {
      tabIndex: 0,
      isLoading: true,
      isClosing: false,
      datepps: '',
      datenow: false,
    }
  },
  computed: {
    ...mapState('treatments', [
      'ownercode',
      'runnercode',
      'treatmentCreated',
      'treatmentsCreatedList',
    ]),
  },
  mounted() {
    console.log('CREANDO PPS: ' + JSON.stringify(this.treatmentCreated))
    this.loadData(0)
  },
  methods: {
    ...mapActions('treatments', [
      'fetchPlots',
      'fetchVarieties',
      'fetchPlagues',
      'fetchProducts',
    ]),
    ...mapMutations('treatments', [
      'saveTreatmentAplication',
      'setPPSDate',
      'clearVariety',
    ]),
    clickTab(tab) {
      console.log('CLICK' + tab)
      this.loadData(tab)
      this.isLoading = false
    },
    clickBefore() {
      if (this.tabIndex == 0) {
        // this.loadData(0)
        this.close()
      } else {
        this.tabIndex -= 1
        this.loadData(this.tabIndex)
      }
      this.isLoading = false
    },
    clickAfter() {
      if (this.tabIndex == 2) {
        this.loadData(2)
      } else {
        this.tabIndex += 1
        this.loadData(this.tabIndex)
      }
      this.isLoading = false
    },
    loadData: async function (tab) {
      if (this.ownercode == '' && this.runnercode == '') {
        this.$router.push({
          path: '/tratamientos',
        })
      }
      if (this.treatmentCreated.plot == '') {
        console.log('He perdido los datos CANCELO ')
        this.$router.push({
          path: '/tratamientos',
        })
      }
      if (this.$route.query.tab > 0) {
        this.tabIndex = this.$route.query.tab
        this.$router.push({
          path: '/pps',
        })
      }
      console.log('En loadData PPS ' + tab)
      console.log(JSON.stringify(this.treatmentCreated))
      this.tabIndex = tab
      this.isLoading = true
      switch (tab) {
        case 0:
          await this.fetchPlagues()
          break
        case 1:
          await this.fetchProducts()
          break
        default:
          break
      }
      console.log(JSON.stringify(this.treatmentCreated))
      this.isLoading = false
    },
    async onSavePPS() {
      if (!this.datenow) return
      this.isLoading = true
      this.saveTreatmentAplication()
      this.isClosing = true
      this.isLoading = false
      this.close()
    },
    async checkLoad(value) {
      console.log('checkLoad ' + value)
    },
    async onChangeDate() {
      console.log('FECHA APLICACION ' + this.datepps)
      this.setPPSDate(this.datepps)
      this.datenow = moment().isAfter(this.datepps)
    },
    close() {
      console.log('CLOSE')
      this.$router.push({
        path: '/crear?tab=2',
      })
    },
  },
}
</script>

<style scoped>
h3 {
  font-size: 1em;
}
h4 {
  font-size: 1em;
  font-weight: 400;
}
</style>
