import { uploadDocument } from '@/services/filesService'
import {
  fetchDocuments,
  createDocument,
  deleteDocument,
} from '@/services/documentsService'

export const documentationModule = {
  namespaced: true,
  state: {
    documents: [],
    fileInfo: { fileName: '', fileList: [], uploaded: false },
  },
  mutations: {
    setDocuments(state, documents) {
      state.documents = documents
    },
    setFileInfo(state, fileInfo) {
      state.fileInfo = fileInfo
    },
    addDocument(state, document) {
      state.documents.push(document)
    },
    removeDocument(state, documentId) {
      const index = state.documents.findIndex(doc => doc.id === documentId)
      if (index !== -1) {
        state.documents.splice(index, 1)
      }
    },
  },
  actions: {
    fetchDocuments: async ({ commit }) => {
      const documents = await fetchDocuments()
      if (documents) {
        commit('setDocuments', documents)
      }
    },
    resetFileUpload: ({ commit }) => {
      commit('setFileInfo', { fileName: '', fileList: [], uploaded: false })
    },
    uploadDocument: async ({ dispatch }, documentInfo) => {
      const document = await createDocument(documentInfo)
      if (document) {
        try {
          await dispatch('uploadFile', document.id)
        } catch (err) {
          await dispatch('deleteDocument', document.id)
          throw err
        }
      }
    },
    uploadFile: async ({ commit, state }, documentId) => {
      const document = await uploadDocument(
        state.fileInfo.fieldName,
        state.fileInfo.fileList,
        documentId
      )
      const fileInfo = { ...state.fileInfo, uploaded: !!document }
      commit('setFileInfo', fileInfo)
      if (document) {
        commit('addDocument', document)
      } else {
        throw new Error('Document not uploaded correctly')
      }
    },
    deleteDocument: async ({ commit }, documentId) => {
      if (await deleteDocument(documentId)) {
        commit('removeDocument', documentId)
        return true
      }
      return false
    },
  },
  getters: {
    findDocumentById(state) {
      return id => {
        const document = state.documents.find(el => el.id === id)
        return document
      }
    },
  },
}
